import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PulloutModelConfiguration, PulloutRecordDataInterface, PulloutRecordInterface } from "../../../models/pullout";
import { ModelConfigurationInterface } from "../../../models/main";

@Component({
  selector: 'wefra-adspending-capture-pullouts',
  templateUrl: './pullouts.component.html',
})
export class AdSpendingsCapturePulloutsComponent implements OnInit {

  @Input() records?: PulloutRecordInterface[]

  pulloutModelConfig: ModelConfigurationInterface = PulloutModelConfiguration

  @Output("cancel") cancelEvent = new EventEmitter<boolean>()
  @Output("change") changeEvent = new EventEmitter<boolean>()

  ngOnInit(): void {
  }

  onKeyUp(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.cancelEvent.emit(true)
    }
  }

  onUpdateSuccess(record: PulloutRecordDataInterface) {
    this.changeEvent.emit(true)
  }
}
