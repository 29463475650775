<ng-container *ngIf="modelConfig">
  <div class="row" *ngIf="! hideControls">
    <div class="col-4">
      <div class="controls">
        <ng-container *ngIf="allowMultiSelect">
          <i-bs ngbTooltip="Halten Sie die Alt-Taste gedrückt, um mehrere Datensätze zu wählen." name="list-check"
                [class.active]="multiSelectMode" width="2rem" height="2rem" />
        </ng-container>
        <i-bs ngbTooltip="Neuer Datensatz" *ngIf="showAddRecordButton" name="plus-circle" class="pointer"
              ng-reflect-name="box-arrow-right" width="1.5rem" height="1.5rem" (click)="newRecord()" />
        <i-bs ngbTooltip="Neu Laden" name="arrow-clockwise" class="pointer" width="1.5rem" height="1.5rem" (click)="load()" />
        <i-bs *ngIf="modelConfig.allowExportXlsx && userIsAllowedToExport" ngbTooltip="Aktuelle Auswahl als Excel exportieren" name="filetype-xlsx" class="pointer" width="1.5rem" height="1.5rem" (click)="exportXls()" />
        <i-bs ngbTooltip="Reset Filter & Seiteneinstellung" name="x-octagon" class="pointer txt-red" width="1.5rem" height="1.5rem" (click)="resetCurrentAndPersistent()" />
      </div>
    </div>

    <div class="col-4">
      <wefra-crud-grid-pagination
        (setPage)="this.setCurrentPage($event)"
        [totalRecordsCount]="totalRecordsCount"
        [currentPage]="currentPage"
        [pageSize]="currentPageSize"
      ></wefra-crud-grid-pagination>
    </div>

    <div class="col-4">
      <div class="pagination-wrapper">
        <div class="total">{{ totalRecordsCount }} Datensätze</div>
        <nav class="pager" aria-label="Pagesize">
          <ul class="pagination pagination-sm justify-content-end">
            <li *ngFor="let size of pageSizes"
              [class.active]="currentPageSize == size"
            >
              <span class="pointer page-link" (click)="setPageSize(size)">{{ (size > 10000) ? '∞' : size }}</span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="table-wrap">
    <table class="table" [class]="modelConfig.serviceSlug">
      <thead>
      <tr>
        <th class="actions" *ngIf="! selectOnly" scope="col">Aktionen</th>
        <ng-container *ngFor="let field of getFields()">
          <th scope="col" [class]="field.prop" [class.name-property]="field.isNameProperty" [class.id-property]="field.isIdProperty" >
            <div class="title-icon-wrap" [class.nosort]="field?.disableSort">
              <ng-container *ngIf="! field?.disableSort">
                <i-bs *ngIf="getSort(field.prop) == 'desc'" name="arrow-up" class="active" (click)="setSort(field.prop, undefined)" ngbTooltip="Absteigend sortiert"></i-bs>
                <i-bs *ngIf="getSort(field.prop) == 'asc'" (click)="setSort(field.prop, 'desc')" class="active" name="arrow-down" ngbTooltip="Aufsteigend sortiert"></i-bs>
                <i-bs *ngIf="getSort(field.prop) == undefined" (click)="setSort(field.prop, 'asc')" name="arrow-down-up" ngbTooltip="Nicht sortiert"></i-bs>
              </ng-container>
              {{ field.title }}
            </div>
            <ng-container *ngIf="field.type == 'bool'">
              <select (change)="onSelectorSearch(field, $event)">
                <option [selected]="! filterRegistry[field.prop]" [value]="undefined"></option>
                <option [selected]="filterRegistry[field.prop] == 1" [value]="1">Ja</option>
                <option [selected]="filterRegistry[field.prop] == 0" [value]="0">Nein</option>
              </select>
            </ng-container>

            <ng-container *ngIf="field.type == 'enum'">
              <select (change)="onSelectorSearch(field, $event)">
                <option [selected]="! filterRegistry[field.prop]" [value]="undefined"></option>
                <option *ngFor="let pair of field.enums" [selected]="filterRegistry[field.prop] == pair.value" [value]="pair.value">{{ formatHtmlText(pair.label) }}</option>
              </select>
            </ng-container>

            <input *ngIf="field.type == 'constraint'" [disabled]="field.disableSearch"
               type="text" (focusout)="this.onQuicksearchFocusOut($event, field)"
               (keyup)="this.onQuicksearchKeyUp($event, field)"
               [value]="getConstraintFilterValue(field)"
            />

            <input *ngIf="field.type == 'date'" [disabled]="field.disableSearch"
               type="date" (focusout)="this.onQuicksearchFocusOut($event, field)"
               (keyup)="this.onQuicksearchKeyUp($event, field)"
               [value]="getDateFilterValue(field)"
            />

            <input *ngIf="field.type == 'virtual'" [disabled]="field.disableSearch"
               type="text" (focusout)="this.onQuicksearchFocusOut($event, field)"
               (keyup)="this.onQuicksearchKeyUp($event, field)"
               [value]="getVirtualFilterValue(field)"
            />

            <input *ngIf="! ['enum', 'bool', 'constraint', 'virtual', 'date'].includes(field.type)" [disabled]="field.disableSearch"
               type="text" (focusout)="this.onQuicksearchFocusOut($event, field)"
               (keyup)="this.onQuicksearchKeyUp($event, field)"
               [value]="filterRegistry[field.prop] ? filterRegistry[field.prop] : ''"
            />
          </th>
        </ng-container>
      </tr>
      </thead>
      <tbody>

      <ng-container *ngIf="isLoading; else loaded">
        <tr>
          <td [colSpan]="getFields().length + 1">
            <div class="d-flex justify-content-center h-100">
              <div class="spinner-border" role="status">
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-template #loaded>
        <tr *ngFor="let record of records" [class.select-only]="selectOnly" [class.selected]="selectionRegistry.includes(record.data[idProperty])">
          <td class="actions" *ngIf="! selectOnly">
            <ng-container *ngIf="this.modelConfig?.readonly === true; else readwrite">
              <span class="view pointer" (click)="onView(record)">
                <i-bs name="eye" ngbTooltip="ansehen"></i-bs>
              </span>
            </ng-container>
            <ng-template #readwrite>
              <ng-container *ngFor="let action of actions">
                <span class="{{ action.identifier }} pointer" (click)="action.method.call(action.scope, record)">
                  <i-bs [name]="action.icon" width="1.2em" height="1.2em" ngbTooltip="{{ action.label }}"></i-bs>
                </span>
              </ng-container>
            </ng-template>
          </td>
          <ng-container *ngFor="let field of getFields()">
            <td *ngIf="field.isIdProperty; else noheader" [class.name-property]="field.isNameProperty" [class.id-property]="field.isIdProperty" class="{{ field.prop }}">
              {{ record.data[field.prop] }}

            </td>
            <ng-template #noheader>
              <ng-container *ngIf="selectOnly">
                <td class="{{ field.prop }} pointer" (click)="onSelect(record)">
                  <wefra-crud-grid-field-renderer [record]="record" [fieldConfig]="field" [grid]="this">
                  </wefra-crud-grid-field-renderer>
                </td>
              </ng-container>
              <ng-container *ngIf="! selectOnly">
                <td class="{{ field.prop }} pointer" *ngIf="field.isNameProperty; else nonameproperty"
                    (click)="modelConfig.readonly === true ? onView(record) : onEdit(record)"
                >
                  <wefra-crud-grid-field-renderer [record]="record" [fieldConfig]="field"></wefra-crud-grid-field-renderer>
                </td>
                <ng-template #nonameproperty>
                  <td class="{{ field.prop }}"><wefra-crud-grid-field-renderer [record]="record" [fieldConfig]="field" [grid]="this"></wefra-crud-grid-field-renderer></td>
                </ng-template>
              </ng-container>

            </ng-template>
          </ng-container>
        </tr>
      </ng-template>
      </tbody>
    </table>
  </div>
</ng-container>
