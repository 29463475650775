import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface WasmuthPublicationDateRecordDataInterface extends RecordDataInterface {
  medium_id: number
  ratecard_id: number
  ratecard_number: string
  publication_date: string
  edition_number: number
  oid: string
  modifydate: string
  edition_name: string
  edition_description: string
  program_since: string
  program_till: string
  format_key: number
  format_number: number
}

export interface WasmuthPublicationDateRecordInterface extends RecordInterface<WasmuthPublicationDateRecordDataInterface> {
}

export const WasmuthPublicationDateModelConfiguration: ModelConfigurationInterface = {
  readonly: true,
  title: "Wasmuth Publication Dates",
  titleSingular: "Wasmuth Publication Dates",
  serviceSlug: "wasmuth_publication_dates",
  fields: [
    { prop: 'medium_id', type: 'int', title: 'Medium'},
    { prop: 'ratecard_id', type: 'int', title: 'Ratecard'},
    { prop: 'ratecard_number', type: 'text', title: 'Ratecard Number'},
    { prop: 'publication_date', type: "datestring", title: 'Erscheinungstermin'},
    { prop: 'edition_name', type: 'text', title: 'Edtionsname'},
    { prop: 'edition_number', type: 'int', title: 'Editionsnummer'},
    { prop: 'oid', type: 'text', title: 'OID'},
    { prop: 'modifydate', type: 'text', title: 'Geändert'},
    { prop: 'edition_description', type: 'text', title: 'Edition Beschreibung'},
    { prop: 'program_since', type: 'text', title: 'Programm seit'},
    { prop: 'program_till', type: 'text', title: 'Programm bis'},
    { prop: 'format_key', type: 'int', title: 'Formatschlüssel'},
    { prop: 'format_number', type: 'int', title: 'Formatnummer'},
  ]
}

