<form *ngIf="form" novalidate [formGroup]="form">
  <ng-container *ngIf="modelConfig">
    <div class="row" *ngFor="let field of getFields()">
      <div class="col-12 mb-3" *ngIf="field.edit !== false">

        <label>{{ field.title }}</label>

        <wefra-constraint-form-field *ngIf="field.type == 'constraint'"
          [record]="record" [fieldConfig]="field" formControlName="{{ field.prop }}">
        </wefra-constraint-form-field>

        <wefra-multi-form-field *ngIf="field.type == 'multi'"
          [record]="record" [fieldConfig]="field" formControlName="{{ field.prop }}"
        >
        </wefra-multi-form-field>

        <input *ngIf="field.type == 'text'"
          type="text" class="form-control" formControlName="{{ field.prop }}"
        />

        <input *ngIf="field.type == 'int'"
               type="number" class="form-control" formControlName="{{ field.prop }}"
        />

        <input *ngIf="['datestring', 'date'].includes(field.type)"
               type="date" class="form-control" formControlName="{{ field.prop }}"
        />

        <input *ngIf="field.type == 'datetime'"
               type="datetime-local" class="form-control" formControlName="{{ field.prop }}"
        />

        <select class="form-control" formControlName="{{ field.prop }}" *ngIf="field.type == 'enum'">
          <option *ngFor="let enum of field.enums" [value]="enum.value">{{ enum.label }}</option>
        </select>

        <wefra-switch-form-field *ngIf="field.type == 'bool'"
               formControlName="{{ field.prop }}"
        />

        <form-field-error text="{{ field.title }}" path="{{ field.prop }}"></form-field-error>

      </div>
    </div>
  </ng-container>
  <div class="d-flex justify-content-start">
    <button type="button" (click)="onSaveAndClose()" class="btn btn-green m-2">OK</button>
    <button type="button" class="btn btn-red m-2" (click)="onClose()">abbrechen</button>
  </div>
</form>
