<ng-container *ngIf="aliasOfPubdateRecords">
  <ng-container *ngIf="aliasOfPubdateRecords!.length > 0">
    <p>Das ist das Erscheinungsdatum, was als Duplikat markiert wird, und in der normalen Übersicht dann nicht mehr auftaucht.</p>
  </ng-container>
  <ng-container *ngIf="aliasOfPubdateRecords!.length == 0">
    <p>Es wurde kein korrespondierendes Datum gefunden. Soll das Erscheinungsdatum ausgeblendet werden?</p>
  </ng-container>
  <wefra-multi-form-field
    [foreignRecord]="aliasPubdateRecord"
    [fieldConfig]="{ title: 'Wasmuth Publication Date', prop: 'egal', type: 'constraint', constraint: { table: 'wasmuth_publication_dates'}}"
    [readonly]="true"
    [selectedRecordTemplate]="wpdRecordTemplate"
  ></wefra-multi-form-field>
  <ng-template #wpdRecordTemplate>
    <ng-container *ngIf="aliasPubdateRecord?.data">
      <dl>
        <dt>Erscheinungstermin</dt><dd>{{ aliasPubdateRecord?.data?.publication_date }}</dd>
        <dt>Heft</dt><dd>{{ aliasPubdateRecord?.data?.edition_number }}</dd>
        <dt>Nummer</dt><dd>{{ aliasPubdateRecord?.data?.edition_name }}</dd>
      </dl>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="aliasOfPubdateRecords!.length > 1; else oneornonerecord">
    <p class="mt-3">Hier das Erscheinungsdatum wählen, was als Duplikat markiert wird, und in der normalen Übersicht dann nicht mehr auftaucht.</p>
    <div class="table-wrap">
      <wefra-mini-grid
        [limitToFields]="['publication_date', 'ratecard_number', 'edition_number', 'edition_name' ]"
        [modelConfig]="pubdateModelConfig"
        [records]="aliasOfPubdateRecords"
        (selectRecord)="onSelectAliasOf($event)"
      ></wefra-mini-grid>
    </div>
  </ng-container>
  <ng-template #oneornonerecord>
    <ng-container *ngIf="aliasOfPubdateRecords!.length == 1; else norecord">
      <p class="mt-3">Es wurde ein Erscheinungsdatum gefunden, was den selben Tag hat. Bitte bestätigen, um die Markierung zu setzen.</p>
      <wefra-multi-form-field
        [foreignRecord]="aliasOfPubdateRecords[0]"
        [fieldConfig]="{ title: 'Wasmuth Publication Date', prop: 'egal', type: 'constraint', constraint: { table: 'wasmuth_publication_dates'}}"
        [readonly]="true"
        [selectedRecordTemplate]="werRecordTemplate"
      ></wefra-multi-form-field>
      <ng-template #werRecordTemplate>
        <ng-container *ngIf="aliasOfPubdateRecords[0]?.data">
          <dl>
            <dt>Erscheinungstermin</dt><dd>{{ aliasOfPubdateRecords[0]!.data!.publication_date }}</dd>
            <dt>Heft</dt><dd>{{ aliasOfPubdateRecords[0]!.data!.edition_number }}</dd>
            <dt>Nummer</dt><dd>{{ aliasOfPubdateRecords[0]!.data!.edition_name }}</dd>
          </dl>
        </ng-container>
      </ng-template>
      <button type="button" (click)="onSubmit()" class="btn btn-green mt-5">Speichern</button>
    </ng-container>
    <ng-template #norecord>
      <button type="button" (click)="onHide()" class="btn btn-green mt-5">Erscheinungsdatum ausblenden</button>
    </ng-template>
  </ng-template>
</ng-container>
<button type="button" (click)="onCancel()" class="btn btn-red float-end mt-5">Abbrechen</button>
