import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface FormatRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  wasmuth_id: number
  numeric_value: number
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface FormatRecordInterface extends RecordInterface<FormatRecordDataInterface> {
}

export const FormatModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "formats",
  allowExportXlsx: true,
  title: "Formate",
  titleSingular: "Format",
  defaultSort: [{ field: 'name', dir: 'asc' }],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { type: "text", title: 'Name', prop: 'name', isNameProperty: true, validators: [ Validators.required ]},
    { prop: "numeric_value", title: "Numeric Value", type: "int" },
   // { type: "constraint", title: 'Branche', prop: 'wasmuth_id', constraint: { table: 'markets' }, validators: [ Validators.required ]},
    { prop: 'wasmuth_id', type: "int", title: "Wasmuth ID"},
    { prop: "created_by", ability: "spa-poweruser", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-poweruser", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-poweruser", type: "datestring", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-poweruser", type: "datestring", title: 'Bearbeitet am', edit: false},
  ]
}
