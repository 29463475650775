<div *ngIf="historicizePreview">
  <p>Es sind folgende Datensätze betroffen:</p>
  <p><strong>Zeitraum {{ historicizePreview.firstFrom | mydate }} - {{ historicizePreview.firstTo | mydate }}</strong></p>
  <p>Printmedien</p>
  <wefra-mini-grid
    [modelConfig]="editionsModelConfig"
    [records]="historicizePreview.firstEditions"
    [limitToFields]="editionsLimitToFields"
  ></wefra-mini-grid>
  <p>Adspendings</p>
  <wefra-mini-grid
    [modelConfig]="adspendingsModelConfig"
    [records]="historicizePreview.firstAdspendings"
    [limitToFields]="adspendingsLimitToFields"
  ></wefra-mini-grid>
  <p><strong>Zeitraum {{ historicizePreview.secondFrom | mydate }} - {{ historicizePreview.secondTo | mydate }}</strong></p>
  <p>Printmedien</p>
  <wefra-mini-grid
    [modelConfig]="editionsModelConfig"
    [records]="historicizePreview.secondEditions"
    [limitToFields]="editionsLimitToFields"
  ></wefra-mini-grid>
  <p>Adspendings</p>
  <wefra-mini-grid
    [modelConfig]="adspendingsModelConfig"
    [records]="historicizePreview.secondAdspendings"
    [limitToFields]="adspendingsLimitToFields"
  ></wefra-mini-grid>

  <form novalidate>
    <button type="button" (click)="onSubmit()" class="btn btn-green mt-4">OK, historisiere!</button>
    <button type="button" (click)="onCancel()" class="btn btn-red mt-4 ms-3">Nein, abbrechen!</button>
  </form>
</div>
