import { ModelConfigurationInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface UserRecordDataInterface {
  id: number
  name: string
  email: string
  email_verified_at: string
  abilities: string[]
  created_at: string
  updated_at: string
}
export interface UserRecordInterface extends RecordInterface<UserRecordDataInterface> {
}

export const UserModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "users",
  allowExportXlsx: false,
  readonly: true,
  title: "Benutzer",
  titleSingular: "Benutzer",
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { prop: 'name', type: "text", title: 'Name', isNameProperty: true, validators: [ Validators.required ]},
    { prop: 'email', type: "text", title: 'Name', validators: [ Validators.required ]},
    { prop: 'status', type: "enum", title: "Status", enums: [
        { value: 0, label: 'aktiv' }, { value: 1, label: 'deaktiviert' }
      ]
    }
  ]
}
