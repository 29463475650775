import {
  FiltersInterface,
  ModelConfigurationFieldInterface,
  ModelConfigurationInterface,
  RecordDataInterface,
  RecordInterface
} from "../models/main";
import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { HttpAbstractRecordService } from "../services/http/record.service.abstract";
import { HttpServiceFactory } from "../services/http/factory";
import { ToastService } from "../services/toast.service";

@Component({
  selector: 'wefra-basic-grid',
  template: `
    <p>
      basic grid
    </p>
  `
})
export class BasicGridComponent<T extends RecordInterface<K>, K extends RecordDataInterface> {

  @Input() public modelConfig?: ModelConfigurationInterface
  @Input() public forceFields: string[] = []
  @Input() public fixedFilters?: FiltersInterface
  @Input() public limitToFields?: string[]
  @Input() records?: T[]
  @Input() public api?: HttpAbstractRecordService<T, K>

  @Output("selectRecord") selectRecordEvent = new EventEmitter<T>()

  isLoading: boolean = true
  idProperty: string = "id"

  constructor(
    @Inject(AuthService) public authService: AuthService,
    @Inject(HttpServiceFactory) public serviceFactory: HttpServiceFactory,
    @Inject(ToastService) public toastService: ToastService
  ) {
  }

  getFields(): ModelConfigurationFieldInterface[] {
    if (! this.modelConfig?.fields) return []
    let fields: ModelConfigurationFieldInterface[] = []

    for (let i = 0; i < this.modelConfig?.fields.length; i++) {
      if (! this.modelConfig.fields[i]) continue

      if (this.modelConfig.fields[i]!.ability) {
        if (! this.authService.hasAbility(this.modelConfig.fields[i]!.ability + '')) {
          continue
        }
      }

      // do not show fields with fixed filter if not forced
      if (this.fixedFilters &&
        (this.fixedFilters[this.modelConfig!.fields[i]!.prop]) &&
        (! this.forceFields.includes(this.modelConfig!.fields[i]!.prop))
      ) {
        continue
      }

      // always show field if it is in limitToFields
      if (this.limitToFields) {
        if (this.limitToFields.includes(this.modelConfig.fields[i]!.prop)) {
          fields.push(this.modelConfig.fields[i]!)
        }
      } else {
        if (this.modelConfig.fields[i]?.list !== false) fields.push(this.modelConfig.fields[i]!)
      }
    }

    return fields
  }

  viewRecordUpdate(replaceRecord: T) {
    this.records?.find((record, index) => {
      if (record.data[this.idProperty] == replaceRecord.data[this.idProperty]) {
        if (this.records) this.records[index] = replaceRecord
      }
    })
  }
}
