import { Injectable } from "@angular/core";
import { HttpBaseService } from "./base.service";
import { MagazineListResponseItemInterface, MonitorResponseInterface } from "../../models/main";
import { MonitorSearchParameters } from "../monitor.service";

@Injectable()
export class HttpMonitorService extends HttpBaseService {
  getMonitorData(params: MonitorSearchParameters) {

    let url = '/api/monitor?from=' + params.from.getFullYear() +
      '-' + (params.from.getMonth() + 1).toString().padStart(2, '0') + '-' + params.from.getDate().toString().padStart(2, '0') +
      '&to=' + params.to.getFullYear() + '-' + (params.to.getMonth() + 1).toString().padStart(2, '0') +
      '-' + params.to.getDate().toString().padStart(2, '0')

    if (params.magazine) {
      url += '&magazine=' + params.magazine
    }

    if (params.publisher) {
      url += '&publisher=' + params.publisher
    }

    if (params.withAliases) {
      url += '&withAliases=' + params.withAliases
    }

    return this.http.get<MonitorResponseInterface>(url)

  }

  getMagazineList() {
    return this.http.get<MagazineListResponseItemInterface[]>('/api/magazinelist')
  }
}
