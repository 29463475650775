import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface ProductRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  company_id: number
  market_id: number
  submarket_id: number
  atc_code: string
  atc_bez: string
  pharmacy_only: number
  prescription_only: number
  drugs: number
  products_new: number
  product_category_id: number
  valid_since: string
  valid_till: string
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
  newsletter_product_id?: number
}

export interface ProductRecordInterface extends RecordInterface<ProductRecordDataInterface> {
}

export const ProductModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "products",
  allowExportXlsx: true,
  title: "Produkte",
  titleSingular: "Produkt",
  allowReplace: true,
  allowHistoricize: false,
  defaultSort: [{ field: 'name', dir: 'asc' }],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { type: "text", title: 'Name', prop: 'name', isNameProperty: true, validators: [ Validators.required ]},
    { type: "constraint", title: 'Firma', prop: 'company_id', constraint: { table: 'companies' }, validators: [ Validators.required ]},
    { type: "constraint", title: 'Branche', prop: 'market_id', constraint: { table: 'markets' }, validators: [ Validators.required ]},
    { type: "constraint", title: 'Unterbranche', prop: 'submarket_id', constraint: { table: 'submarkets' }, validators: [ ]},
    { type: "text", title: 'ATC Code', prop: 'atc_code', validators: [ ]},
    { type: "text", title: 'ATC Description', prop: 'atc_bez', validators: [ ]},
    { type: "int", title: 'NLP ID', prop: 'newsletter_product_id', validators: [ ]},
    { type: "bool", title: 'Apothekenpflichtig', prop: 'pharmacy_only', validators: [  ]},
    { type: "bool", title: 'Rezeptpflichtig', prop: 'prescription_only', validators: [  ]},
    { type: "bool", title: 'BTM Rezept', prop: 'drugs', validators: [  ]},
    { prop: 'valid_since', type: "datestring", title: "Gültig ab", validators: [ Validators.required ]},
    { prop: 'valid_till', type: "datestring", title: "Gültig bis"},
    { prop: "created_by", ability: "spa-poweruser", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-poweruser", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-poweruser", type: "datestring", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-poweruser", type: "datestring", title: 'Bearbeitet am', edit: false},
    // { type: "int", title: 'PNEU', prop: 'products_new', validators: [  ]},
    // { type: "constraint", title: 'Produktkategorie', prop: 'product_category_id', constraint: { table: 'product_categories' }, validators: [ ]},
  ]
}
