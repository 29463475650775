import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecordDataInterface, RecordInterface } from "../models/main";
import { BasicGridComponent } from "../basic-grid/baslc-grid.component";
import { Subject } from "rxjs";
import { HttpAbstractRecordService } from "../services/http/record.service.abstract";

export interface EditGridComponentFieldCoordinatesInterface {
  id: number | string
  prop: string
}

export interface EditGridComponentUpdateResponseInterface {
  field: EditGridComponentFieldCoordinatesInterface
  record: null | RecordInterface<RecordDataInterface>
  success: boolean
}

@Component({
  selector: 'wefra-edit-grid',
  templateUrl: './edit-grid.component.html',
  styleUrls: []
})
export class EditGridComponent<T extends RecordInterface<K>, K extends RecordDataInterface> extends BasicGridComponent<T, K> implements OnInit {

  @Input() editableFields: string[] = []

  @Output("updateRecord") updateRecordEvent = new EventEmitter<K>()

  public clickFieldSubject: Subject<EditGridComponentFieldCoordinatesInterface> = new Subject<EditGridComponentFieldCoordinatesInterface>();
  public saveResponseSubject: Subject<EditGridComponentUpdateResponseInterface> = new Subject<EditGridComponentUpdateResponseInterface>();

  ngOnInit(): void {
    if (this.modelConfig && ! this.api) {
      this.api = this.serviceFactory.getTypeInstance(this.modelConfig.serviceSlug) as HttpAbstractRecordService<T, K>
    }
  }

  onClickField(prop: string, id: number | string) {
    this.clickFieldSubject.next({prop: prop, id: id})
  }

  onUpdateRecord(record: T, prop: string) {
    if (! this.api) return
    this.api.update(record.data).subscribe(response => {
      this.viewRecordUpdate(response)
      this.toastService.showSuccess( this.modelConfig?.titleSingular + ' wurde erfolgreich aktualisiert!')
      this.saveResponseSubject.next({ success: true, record: response, field: { id: record.data.id!, prop: prop }})
      this.updateRecordEvent.emit(record.data)
    }, (error) => {
      this.toastService.showApiError(error, this.modelConfig?.titleSingular + ' konnte nicht aktualisiert werden!')
      this.saveResponseSubject.next({ success: false, record: record, field: { id: record.data.id!, prop: prop }})
    })
  }
}
