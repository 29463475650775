export function renderDateFromString(str?: string) {
  if (! str) return ""

  // is a datetime, but we handle it like a date
  let matches = str.match(/^(\d{4})-(\d\d)-(\d\d) \d\d:\d\d:\d\d$/)
  if (matches && matches.length == 4) {
    return matches[3] + '.' + matches[2] + '.' + matches[1]
  }

  // is a date
  matches = str.match(/^(\d{4})-(\d\d)-(\d\d)$/)
  if (matches && matches.length == 4) {
    return matches[3] + '.' + matches[2] + '.' + matches[1]
  }

  // is a utc datetime
  matches = str.match(/^(\d{4})-(\d\d)-(\d\d)T\d\d:\d\d:\d\d\.000000Z$/)
  if (matches && matches.length == 4) {
    return matches[3] + '.' + matches[2] + '.' + matches[1]
  }

  // did not match
  return str
}

export function getWeekOfYear(date: Date): number {
  const currentThursday = new Date(date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000)
  const yearOfThursday = currentThursday.getFullYear()
  const firstThursday = new Date(new Date(yearOfThursday, 0, 4).getTime() + (3 - ((new Date(yearOfThursday, 0, 4).getDay() + 6) % 7)) * 86400000)

  return Math.floor(1 + 0.5 + (currentThursday.getTime() - firstThursday.getTime()) / 86400000 / 7)
}

export class MyDate extends Date {
  addDay(days: number): MyDate {
    this.setDate(this.getDate() + days)
    return this
  }

  subDay(days: number) {
    return this.addDay(days * -1)
  }

  addMonth(months: number): MyDate {
    this.setMonth(this.getMonth() + months)
    return this
  }

  getWeekOfYear() {
    const currentThursday = new Date(this.getTime() + (3 - ((this.getDay() + 6) % 7)) * 86400000)
    const yearOfThursday = currentThursday.getFullYear()
    const firstThursday = new Date(new Date(yearOfThursday, 0, 4).getTime() + (3 - ((new Date(yearOfThursday, 0, 4).getDay() + 6) % 7)) * 86400000)
    return Math.floor(1 + 0.5 + (currentThursday.getTime() - firstThursday.getTime()) / 86400000 / 7)
  }

  getMonthOfYear(): number {
    return this.getMonth() + 1
  }

  isSameDay(date: MyDate): boolean {
    const a = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate()
    const b = this.getFullYear() + '-' + this.getMonth() + '-' + this.getDate()

    if (a === b) {
      return true
    }
    return false
  }

  getDateString() {
    return this.getYearAndMonth() + '-' + (this.getDate() < 10 ? '0' : '') + this.getDate()
  }

  getYearAndMonth(): string {
    let month = this.getMonthOfYear() + ''
    if (month.length == 1) month = '0' + month
    return this.getFullYear() + '-' + month
  }
}
