import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { ModelConfigurationInterface, MonitorResponseItemInterface } from "../models/main";
import { HttpWasmuthPublicationDateService } from "../services/http/wasmuth_publication_date.service";
import { PubdateAliasRecordDataInterface } from "../models/pubdate_alias";
import {
  WasmuthPublicationDateModelConfiguration,
  WasmuthPublicationDateRecordInterface
} from "../models/wasmuth_publication_dates";

@Component({
  selector: 'wefra-monitor-alias-component',
  templateUrl: './alias.component.html',
})
export class MonitorAliasComponent implements OnInit {

  @Output("saveAlias") saveAliasEvent = new EventEmitter<PubdateAliasRecordDataInterface>()
  @Output("hideRecord") hideRecordEvent = new EventEmitter<PubdateAliasRecordDataInterface>()
  @Output("close") closeEvent = new EventEmitter<boolean>()
  @Input({ required: true }) monitorItem?: MonitorResponseItemInterface
  @Input() readonly: boolean = true

  aliasRecord?: PubdateAliasRecordDataInterface

  aliasPubdateRecord?: WasmuthPublicationDateRecordInterface
  aliasOfPubdateRecords?: WasmuthPublicationDateRecordInterface[]
  pubdateModelConfig: ModelConfigurationInterface = WasmuthPublicationDateModelConfiguration

  form?: FormGroup

  constructor(
    @Inject(HttpWasmuthPublicationDateService) private publicationDateService: HttpWasmuthPublicationDateService,
  ) {
  }

  onSubmit() {
    if (! this.aliasPubdateRecord) return
    if (! this.aliasOfPubdateRecords || ! (this.aliasOfPubdateRecords.length >= 1) || ! this.aliasOfPubdateRecords[0]) return
    this.createAliasRecord(this.aliasPubdateRecord, this.aliasOfPubdateRecords[0])
    this.saveAliasEvent.emit(this.aliasRecord)
  }

  onHide() {
    if (! this.aliasPubdateRecord) return
    this.createAliasRecord(this.aliasPubdateRecord, this.aliasPubdateRecord)
    this.hideRecordEvent.emit(this.aliasRecord)
  }

  onSelectAliasOf(record: WasmuthPublicationDateRecordInterface) {
    if (! this.aliasPubdateRecord) return
    this.createAliasRecord(this.aliasPubdateRecord, record)
    this.saveAliasEvent.emit(this.aliasRecord)
  }

  onCancel() {
    this.closeEvent.emit(true)
  }

  private createAliasRecord(alias: WasmuthPublicationDateRecordInterface, aliasOf: WasmuthPublicationDateRecordInterface) {
    this.aliasRecord = {
      alias_medium_id: alias.data.medium_id,
      alias_ratecard_id: alias.data.ratecard_id,
      alias_ratecard_number: alias.data.ratecard_number,
      alias_publication_date: alias.data.publication_date,
      alias_edition_number: alias.data.edition_number,
      alias_format_key: alias.data.format_key,
      alias_format_number: alias.data.format_number,

      alias_of_medium_id: aliasOf.data.medium_id,
      alias_of_ratecard_id: aliasOf.data.ratecard_id,
      alias_of_ratecard_number: aliasOf.data.ratecard_number,
      alias_of_publication_date: aliasOf.data.publication_date,
      alias_of_edition_number: aliasOf.data.edition_number,
      alias_of_format_key: aliasOf.data.format_key,
      alias_of_format_number: aliasOf.data.format_number,
    }
  }

  ngOnInit(): void {
    this.load()
  }

  load() {
    if (!this.monitorItem) return
    let that = this
    if (! this.aliasPubdateRecord) {
      this.publicationDateService.list({
        filters: {
          medium_id: that.monitorItem?.medium_id + '',
          ratecard_id: that.monitorItem?.ratecard_id + '',
          ratecard_number: that.monitorItem?.ratecard_number + '',
          publication_date: that.monitorItem?.publication_date + '',
          edition_number: that.monitorItem?.edition_number + '',
          format_key: that.monitorItem?.format_key + '',
          format_number: that.monitorItem?.format_number + '',
        },
        paging: {
          page: 1, size: 20
        },
        sorting: [
          {field: "publication_date", dir: "asc"}
        ]
      }).subscribe(response => {
        this.aliasPubdateRecord = response.data.pop()
      })
    }

    if (! this.aliasOfPubdateRecords) {
      this.publicationDateService.list({
        filters: {
          medium_id: that.monitorItem?.medium_id + '',
          ratecard_id: that.monitorItem?.ratecard_id + '',
          ratecard_number: that.monitorItem?.ratecard_number + '',
          publication_date: that.monitorItem?.publication_date + '',
          edition_number: that.monitorItem?.edition_number + '',
          format_key: that.monitorItem?.format_key + '',
          format_number: that.monitorItem?.format_number + '',
        },
        paging: {
          page: 1, size: 2
        },
        sorting: [
          {field: "publication_date", dir: "asc"}
        ]
      }).subscribe(response => {
        this.aliasPubdateRecord = response.data.pop()
      })
    }
    if (! this.aliasOfPubdateRecords) {
      this.publicationDateService.list({
        filters: {
          medium_id: that.monitorItem?.medium_id + '',
          ratecard_id: that.monitorItem?.ratecard_id + '',
          publication_date: that.monitorItem?.publication_date + '',
        },
        paging: {
          page: 1, size: 20
        },
        sorting: [
          {field: "publication_date", dir: "asc"}
        ]
      }).subscribe(response => {
        this.aliasOfPubdateRecords = response.data.filter(record => {
          if (
            record.data.edition_number == that.monitorItem?.edition_number &&
            record.data.format_key == that.monitorItem?.format_key &&
            record.data.format_number == that.monitorItem?.format_number
          ) return false
          return true
        })
      })
    }
  }
}
