import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ToastService } from "../../services/toast.service";
import { HttpEditionService } from "../../services/http/edition.service";
import { EditionModelConfiguration, EditionRecordInterface } from "../../models/edition";
import { FormBuilder } from "@angular/forms";
import {
  FiltersInterface,
  ModelConfigurationFieldInterface,
  ModelConfigurationInterface,
  RecordDataInterface,
  RecordInterface
} from "../../models/main";
import {
  WasmuthMediaRatecardModelConfiguration,
  WasmuthMediaRatecardRecordInterface
} from "../../models/wasmuth_media_ratecards";
import { HttpWasmuthMediaRatecardService } from "../../services/http/wasmuth_media_ratecard.service";
import { HttpWasmuthPublisherService } from "../../services/http/wasmuth_publisher.service";
import { WasmuthPublisherRecordInterface } from "../../models/wasmuth_publisher";
import { HttpWasmuthPublicationDateService } from "../../services/http/wasmuth_publication_date.service";
import {
  WasmuthPublicationDateModelConfiguration,
  WasmuthPublicationDateRecordInterface
} from "../../models/wasmuth_publication_dates";
import { AuthService } from "../../services/auth.service";
import Pusher from "pusher-js";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { MagazineRecordDataInterface, MagazineRecordInterface } from "../../models/magazine";
import { ModelConfigFactory } from "../../models/factory";
import { WefraRatecardRecordInterface } from "../../models/wefra_ratecard";
import { WasmuthMediaEditionRecordInterface } from "../../models/wasmuth_media_editions";
import { HttpWasmuthMediaEditionService } from "../../services/http/wasmuth_media_edition.service";
import { HttpMagazineService } from "../../services/http/magazine.service";
import { ConstraintComponent } from "../../form/generic-form/field/constraint.component";

@Component({
  selector: 'wefra-ad-spendings-upload',
  templateUrl: './upload.component.html',
})
export class UploadComponent implements OnInit, OnDestroy {
  @Output("onCreateRecord") onCreateRecordEvent = new EventEmitter<EditionRecordInterface>()

  editionRecord?: EditionRecordInterface
  modelConfig: ModelConfigurationInterface = EditionModelConfiguration
  mediaRateCardModelConfig: ModelConfigurationInterface = WasmuthMediaRatecardModelConfiguration
  publicationDateModelConfiguration: ModelConfigurationInterface = WasmuthPublicationDateModelConfiguration
  leftColumnCount: number = 4
  rightColumnCount: number = 8
  mediaRateCard?: WasmuthMediaRatecardRecordInterface
  publisher?: WasmuthPublisherRecordInterface
  publicationDateRecord?: WasmuthPublicationDateRecordInterface
  recordCompleted: boolean = false
  recordUpdated: boolean = false
  recordWasNew: boolean = false
  pusher?: Pusher
  mediaEdition?: WasmuthMediaEditionRecordInterface
  wefraMagazin?: MagazineRecordInterface
  currentPage: number = 1
  pageArray: number[] = []
  // mediaRatecardPresetFilters: FiltersInterface
  circulationFieldConfig: ModelConfigurationFieldInterface
  touched: boolean = false

  @ViewChild('magazineSelector') magazineSelector!: ConstraintComponent<MagazineRecordInterface, MagazineRecordDataInterface>


  constructor(
    @Inject(HttpEditionService) private http: HttpEditionService,
    @Inject(HttpWasmuthMediaRatecardService) public httpMediaRatecardService: HttpWasmuthMediaRatecardService,
    @Inject(HttpMagazineService) public httpMagazineService: HttpMagazineService,
    @Inject(HttpWasmuthPublisherService) public httpMediaPublisherService: HttpWasmuthPublisherService,
    @Inject(HttpWasmuthPublicationDateService) public httpPubdateService: HttpWasmuthPublicationDateService,
    @Inject(HttpWasmuthMediaEditionService) public mediaEditionService: HttpWasmuthMediaEditionService,
    @Inject(ToastService) private toastService: ToastService,
    public fb: FormBuilder,
    protected rawHttp: HttpClient,
    @Inject(AuthService) private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.circulationFieldConfig = {
      title: 'Auflage', prop: 'circulation_print', type: 'constraint',
      constraint: { table: 'imported_circulations'},
      renderer: (record: RecordInterface<RecordDataInterface>) => { return record.data["circulation_print"]}
    }
    //this.mediaRatecardPresetFilters = { valid_since: new Date().getFullYear() }
  }
  ngOnInit() {
    const id = parseInt(this.route.snapshot.paramMap.get('editionRecordId')+'');
    this.http.get(id).subscribe(edition => {
      this.editionRecord = edition
      if (! edition.data.magazine_id) {
        this.recordWasNew = true
      }
      this.publicationDateRecord = edition.resolved['wasmuth_publication_date'] as WasmuthPublicationDateRecordInterface
      this.publisher = edition.resolved['wasmuth_publisher_id'] as WasmuthPublisherRecordInterface
      this.mediaRateCard = edition.resolved['wasmuth_media_ratecard'] as WasmuthMediaRatecardRecordInterface

      this.pageArray = [...Array(this.editionRecord.data.pagecount).keys()]

      // preload images
      for(let i = 1; i < this.pageArray.length; i++) {
        let img = new Image();
        img.src = '/files/editions/' + this.editionRecord.data.file_reference + '/' + i + '/large.webp'
      }
    }, error => {
    })
  }

  handleCtrlSpin(amount: number) {
    if (amount < 0 && this.currentPage > 1) this.currentPage--
    else if (amount > 0 && this.editionRecord && this.currentPage < this.editionRecord.data.pagecount) this.currentPage++
  }

  getFixedFilters(prop: string): FiltersInterface {
    if (prop == "wasmuth_publication_date") {
      if (! this.mediaRateCard) return {}
      return {
        medium_id: this.mediaRateCard.data.medium_id,
        ratecard_id: this.mediaRateCard.data.ratecard_id,
        ratecard_number: this.mediaRateCard.data.ratecard_number
      }
    }

    if (prop == "imported_circulations") {
      return {
        quarter: 4,
      }
    }


    return {}
  }

  onChangeCirculation(v?: number | string) {
    if (! this.editionRecord) return
    if (v !== undefined) {
      this.editionRecord.data["circulation_print"] = ( v == '') ? 0 : parseInt(v+'')
    }
  }

  onSelectWefraRateCard(record?: RecordInterface<RecordDataInterface>) {
    if (! record) {
      this.onRemoveWefraRateCard()
      return
    }
    const castrecord = record as WefraRatecardRecordInterface
    if (this.editionRecord && record.data["id"]) this.editionRecord.data["wefra_ratecard_id"] = castrecord.data["id"]
  }

  onRemoveWefraRateCard() {
    if (this.editionRecord) {
      this.editionRecord.data["wefra_ratecard_id"] = ""
      delete this.editionRecord.resolved["wefra_ratecard_id"]
      this.onRemoveWefraPubdate()
    }
  }
  onRemoveWefraPubdate() {
    if (this.editionRecord) {
      this.editionRecord.data["wefra_pubdate_id"] = ""
      delete this.editionRecord.resolved["wefra_pubdate_id"]
    }
  }

  onSelectMediaRateCard(record?: RecordInterface<RecordDataInterface>) {
    let castrecord = record ? record as WasmuthMediaRatecardRecordInterface : undefined
console.warn(castrecord)
    if (castrecord) {
      const publisherId = castrecord.resolved["ratecard_id"]!.data['publisher_id']
      if (! publisherId) {
        this.toastService.showError('Konnte den Publisher für diese Media Rate Card nicht finden!')
        return
      }

      this.httpMediaPublisherService.get(publisherId).subscribe(publisher => {
        this.publisher = publisher
        this.mediaRateCard = castrecord
        if (this.mediaRateCard && this.editionRecord) {
          this.editionRecord.data.wasmuth_publisher_id = this.publisher.data.publisher_id
          this.editionRecord.data.wasmuth_medium_id = this.mediaRateCard.data.medium_id
          this.editionRecord.data.wasmuth_ratecard_id = this.mediaRateCard.data.ratecard_id
          this.editionRecord.data.wasmuth_ratecard_number = this.mediaRateCard.data.ratecard_number
        } else if (this.editionRecord) {
          this.editionRecord.data.wasmuth_publisher_id = undefined
          this.editionRecord.data.wasmuth_medium_id = undefined
          this.editionRecord.data.wasmuth_ratecard_id = undefined
          this.editionRecord.data.wasmuth_ratecard_number = undefined
        }
      }, error => {
        this.toastService.showError("Es konnte kein Publisher zu dieser Ratecard gefunden werden. Bitte Wasmuth Stammdaten aktualisieren!")
      })

      this.mediaEditionService.list({
        filters: {
          medium_id: castrecord.data.medium_id,
          ratecard_id: castrecord.data.ratecard_id,
          ratecard_number: castrecord.data.ratecard_number,
          edition_type_key: 4
        },
        paging: {page: 1, size: 10},
        sorting: []
      }).subscribe(mediaEditions => {
        if (! this.editionRecord) return
        if (mediaEditions.total > 0) {
          this.mediaEdition = mediaEditions.data[0]
          this.editionRecord.data.circulation_print = this.mediaEdition ? this.mediaEdition.data.edition : 0
        } else this.mediaEdition = undefined
      })

      this.httpMagazineService.list({
        filters: {
          wasmuth_id: castrecord.data.ratecard_id,
          valid_at: castrecord.resolved["ratecard_number"]?.data["valid_since"],
        },
        paging: {page: 1, size: 10},
        sorting: []
      }).subscribe(magazines => {
        if (! magazines) {
          this.toastService.showError("Es konnte kein Magazin gefunden werden!")
          return
        }
        if (magazines.data.length > 1) {
          this.toastService.showInfo("Es gibt mehrere Magazine, die die gleiche Ratecard zugeordnet haben, daher war eine automatische Zuweisung des Magazines nicht möglich.")
        } else if (magazines.data.length < 1) {
          this.toastService.showError("Es konnte kein Magazin gefunden werden!")
        } else {
          this.onSelectMagazine(magazines.data[0]!)
          this.magazineSelector.setViewValueByResolvedRelation()
        }
      })

    } else {
      this.mediaRateCard = undefined
      this.publisher = undefined
      this.mediaEdition = undefined
      if (this.editionRecord) {
        this.editionRecord.data.wasmuth_medium_id = undefined
        this.editionRecord.data.wasmuth_ratecard_id = undefined
        this.editionRecord.data.wasmuth_ratecard_number = undefined
        this.editionRecord.data.wasmuth_publisher_id = undefined

        delete this.editionRecord!.data.magazine_id
        delete this.editionRecord!.resolved["magazine_id"]
        this.magazineSelector.setViewValueByResolvedRelation()
      }
      // also clear dependent publication_date
      this.onSelectPublicationDate(undefined)
    }
  }

  onSelectMagazine(record: RecordInterface<RecordDataInterface>) {
    this.wefraMagazin = record as MagazineRecordInterface
    if (this.editionRecord) {
      if (this.wefraMagazin) {
        this.editionRecord.data.magazine_id = record.data.id
        this.editionRecord.resolved["magazine_id"] = record as MagazineRecordInterface
      } else {
        this.editionRecord.data.magazine_id = undefined
        delete this.editionRecord.resolved["magazine_id"]
      }
    }
  }

  onSelectPublicationDate(record?: RecordInterface<RecordDataInterface>) {
    if (! record) {
      this.publicationDateRecord = undefined
      if (this.editionRecord) {
        this.editionRecord.data.wasmuth_publication_date = undefined
        this.editionRecord.data.wasmuth_edition_name = undefined
        this.editionRecord.data.wasmuth_edition_number = undefined
        this.editionRecord.data.wasmuth_format_key = undefined
        this.editionRecord.data.wasmuth_format_number = undefined
      }
      return
    }
    const castrecord = record as WasmuthPublicationDateRecordInterface
    this.publicationDateRecord = castrecord
    if (this.editionRecord) {
      this.editionRecord.data.wasmuth_publication_date = this.publicationDateRecord?.data.publication_date
      this.editionRecord.data.wasmuth_edition_name = this.publicationDateRecord?.data.edition_name
      this.editionRecord.data.wasmuth_edition_number = this.publicationDateRecord?.data.edition_number
      this.editionRecord.data.wasmuth_format_key = this.publicationDateRecord?.data.format_key
      this.editionRecord.data.wasmuth_format_number = this.publicationDateRecord?.data.format_number
    }
  }

  hasAnyRatecard() {
    if (! this.editionRecord) return false
    if (
      (! this.editionRecord.data.wasmuth_ratecard_id)
      && (! this.editionRecord.data.wefra_ratecard_id)
    ) return false
    return true
  }

  isWasmuthRatecardValid() {
    if (! this.editionRecord) return false
    if (! this.hasAnyRatecard()) {
      return false
    }

    return true
  }
  isWefraRatecardValid() {
    if (! this.editionRecord) return false
    if (! this.hasAnyRatecard()) {
      return false
    }

    return true
  }
  isWasmuthPdValid() {
    if (! this.editionRecord) return false
    if (this.editionRecord.data.wasmuth_ratecard_id) {
      if (! this.editionRecord.data.wasmuth_publication_date) {
        return false
      }
    }
    return true
  }
  isWefraPdValid() {
    if (! this.editionRecord) return false
    if (this.editionRecord.data.wefra_ratecard_id) {
      if (! this.editionRecord.data.wefra_pubdate_id) {
        return false
      }
    }
    return true
  }
  isMagazineValid() {
    if (! this.editionRecord) return false
    return true
  }

  submitAndCapture() {
    this.submit()?.subscribe(edition => {
      this.router.navigateByUrl("ad-spendings/capture/" + edition.data.id)
    })
  }

  submit(update?: boolean) {

    this.touched = true

    if (! this.editionRecord) {
      this.toastService.showError("Upload ist verloren gegangen, bitte Vorgang wiederholen!")
      return
    }

    if (! update) {
      this.editionRecord.data.capture_state = 1
    }

    if (! (this.isMagazineValid() &&
        this.isWasmuthRatecardValid() &&
        this.isWasmuthPdValid() &&
        this.isWefraRatecardValid() &&
        this.isWefraPdValid()
    )) {
      this.toastService.showError("Das Formular ist noch nicht vollständig ausgefüllt!")
      return
    }

    let obs = this.http.update(JSON.parse(JSON.stringify(this.editionRecord.data)))
    obs.subscribe(edition => {
      if (update) {
        this.recordUpdated = true
        this.toastService.showSuccess('Das Printmedium wurde erfolgreich aktualisiert!')
      } else {
        this.recordCompleted = true
      }

    }, error => {
      this.toastService.showApiError(error, 'Das Printmedium konnte nicht angelegt werden!')
    })

    return obs
  }
  onChangePrependPages(e: Event) {
    let el = e.target as HTMLInputElement
    if (this.editionRecord) this.editionRecord.data.prepend_pages = parseInt(el.value)
  }

  onChangeAppendPdfPages(e: Event) {
    let el = e.target as HTMLInputElement
    if (this.editionRecord) this.editionRecord.data.append_adspages = parseInt(el.value)
  }

  onChangePrependPdfPages(e: Event) {
    let el = e.target as HTMLInputElement
    if (this.editionRecord) this.editionRecord.data.prepend_adspages = parseInt(el.value)
  }


  ngOnDestroy(): void {
  }

  protected readonly ModelConfigFactory = ModelConfigFactory;
}
