<span class="wrapper" [class.loading]="updating" [class.success]="updateSuccess === true" [class.error]="updateSuccess === false">
  <ng-container *ngIf="editing; else noedit">
    <input type="text" (focusout)="onFocusOut($event)" (keyup)="onKeyUp($event)" [value]="value" #input>
  </ng-container>
  <ng-template #noedit>
    <wefra-crud-grid-field-renderer
      [fieldConfig]="fieldConfig"
      [record]="record"
      [context]="'mini-grid'"
    ></wefra-crud-grid-field-renderer>
  </ng-template>
</span>
