import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface EditionRecordDataInterface extends RecordDataInterface {
  id?: number
  file_reference: string
  pagecount: number
  wasmuth_medium_id?: number
  wasmuth_ratecard_id?: number
  wasmuth_ratecard_number?: string
  wasmuth_publisher_id?: number
  wasmuth_edition_name?: string
  wasmuth_publication_date?: string
  wasmuth_format_key?: number
  wasmuth_format_number?: number
  wasmuth_edition_number?: number
  created_by: number
  modified_by: number
  created_at: string
  modified_at: string
  circulation_print: number
  capture_state: number
  magazine_id?: number
  prepend_pages: number
  wefra_pubdate_id?: number | string
  wefra_ratecard_id?: number | string
  prepend_adspages: number
  append_adspages: number
  is_checked: number
}


export interface EditionRecordInterface extends RecordInterface<EditionRecordDataInterface> {

}

export const EditionModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "editions",
  allowExportXlsx: true,
  title: "Printmedien",
  titleSingular: "Printmedium",
  defaultSort: [{ field: 'id', dir: 'desc' }],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { prop: 'capture_state', type: "enum", title: "Status", enums: [
        { value: 0, label: 'Nicht erfasst', class: 'scanned'},
        { value: 1, label: 'In Er&shy;fas&shy;sung', class: 'capturing'},
        { value: 2, label: 'Voll&shy;stän&shy;dig', class: 'complete'}
    ]},
    { prop: 'is_checked', type: "bool", title: 'OK', ability: "spa-poweruser", renderer: (record) => {
        const check = record.data["is_checked"] ? '<path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>' : ''
        const color = record.data["is_checked"] ? 'green' : '#a02c2c'
        return '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="' + color + '" viewBox="0 0 16 16">' +
          '<path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>' +
          check +
          '</svg>'
      }, action: (record?, grid?) => {
        if (! record) return
        if (! grid) return

        if (! record.data["magazine_id"]) {
          grid.toastService.showError('Printmedien, die kein Magazin zugewiesen haben, können nicht auf geprüft gesetzt werden!')
          return
        }

        record.data["is_checked"] = parseInt(record.data["is_checked"]) == 1 ? 0 : 1

        grid.api?.update(record.data).subscribe(responseRecord => {
          if (record.data["is_checked"] == 1) {
            grid.toastService.showSuccess('Der Datensatz wurde auf geprüft gesetzt!')
          } else {
            grid.toastService.showSuccess('Der Datensatz wurde auf ungeprüft gesetzt!')
          }
          grid.viewRecordUpdate(responseRecord)
        }, error => {
          grid.toastService.showError('Der Datensatz konnte nicht aktualisiert werden!')
        }, () => {
        })
      }
    },
    { prop: 'file_reference', type: "editionHash", title: 'Titel', validators: [ Validators.required ], edit: false, filterProperty: 'file_basename'},
    { prop: 'file_basename', type: "text", title: 'Dateiname', edit: false },
    { prop: 'pagecount', type: "int", title: 'Seiten', validators: [ Validators.required ], edit: false},
    { prop: 'wasmuth_medium_id', type: "constraint", title: 'Medium', constraint: { table: 'wasmuth_media' }, validators: [ Validators.required ]},
    { prop: 'wasmuth_ratecard_id', type: "constraint", title: 'Ratecard', constraint: { table: 'wasmuth_ratecards' }, validators: [ Validators.required ]},
    { prop: 'wasmuth_ratecard_number', type: "constraint", title: 'RC Nummer', constraint: { table: 'wasmuth_ratecard_numbers' }, validators: [ Validators.required ]},
    { prop: 'wasmuth_publisher_id', type: "constraint", title: 'Publisher', constraint: { table: 'wasmuth_publishers' }, validators: [ Validators.required ]},
    { prop: 'wasmuth_publication_date', type: "datetime", title: 'Erscheinungsdatum', validators: [ Validators.required ], edit: false},
    { prop: 'wasmuth_format_key', type: "int", title: 'Format Key', validators: [ Validators.required ], edit: false, list: false},
    { prop: 'wasmuth_format_number', type: "int", title: 'Format Nummer', validators: [ Validators.required ], edit: false, list: false },
    { prop: 'wasmuth_edition_name', type: "text", title: 'Editionsname', validators: [ Validators.required ], edit: true },
    { prop: 'circulation_print', type: "int", title: 'Auflage' },
    { prop: "magazine_id", type: "constraint", title: 'WEFRA Magazin', constraint: { table: 'magazines' }, validators: [ Validators.required ]},
    { prop: 'wasmuth_edition_number', type: "int", title: 'No.', validators: [ Validators.required ], edit: false, list: false },
    { prop: 'wefra_ratecard_id', type: "constraint", title: 'Wefra Ratecard', constraint: { table: 'wefra_ratecards' }, renderer: (record: RecordInterface<RecordDataInterface>) => {
        return record.resolved["magazine_id"]?.data["name"]
      }, filterProperty: "wefraRatecardId.magazineId.name"},
    { prop: 'wefra_pubdate_id', type: "constraint", title: 'Wefra Erscheinungsdatum', constraint: { table: 'wefra_pubdates' },
      renderer: (record: RecordInterface<RecordDataInterface>) => {
        return record.resolved["wefra_pubdate_id"]?.data["pubdate"] + ' (' + record.resolved["wefra_pubdate_id"]?.data["edition_number"] + ')'
      }
    },
    { prop: 'prepend_pages', type: "int", title: 'Seitenanzahl voranstellen' },
    { prop: 'prepend_adspages', type: "int", title: 'PDF-Seiten voranstellen' },
    { prop: 'append_adspages', type: "int", title: 'PDF-Seiten anhängen' },
    { prop: "created_by", ability: "spa-poweruser", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-poweruser", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-poweruser", type: "datestring", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-poweruser", type: "datestring", title: 'Bearbeitet am', edit: false},
  ]
}
