import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpAdSpendingsService } from "../../services/http/ad_spendings.service";
import { HttpEditionService } from "../../services/http/edition.service";
import { EditionRecordInterface } from "../../models/edition";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AdSpendingRecordDataInterface, AdSpendingRecordInterface } from "../../models/ad_spending";
import { ToastService } from "../../services/toast.service";
import { FiltersInterface, PriceQueryDataInterface, RecordDataInterface, RecordInterface } from "../../models/main";
import { ProductRecordInterface } from "../../models/product";
import { SubmarketRecordInterface } from "../../models/submarket";
import { ModelConfigFactory } from "../../models/factory";
import { ModalService } from "../../services/modal.service";
import { AdSpendingsIsMultiQuestionComponent } from "../is-multi-question/is-multi-question.component";
import { PriceRecordInterface } from "../../models/price";
import { LocalStorageService } from "../../services/localstorage.service";
import { StateService } from "../../services/state.service";
import { HttpWasmuthAdCostService } from "../../services/http/wasmuth_ad_cost.service";
import { CrudGridComponent } from "../../crud-grid/crud-grid.component";
import { CrudGridDeleteComponent } from "../../crud-grid/delete.component";
import { getWeekOfYear } from "../../helpers/main";
import { AdSpendingsCaptureSearchGridComponent } from "./search-grid.component";
import { AdSpendingsPullOutQuestionComponent } from "../pull-out-question/pull-out-question.component";
import { PdfPaginationService } from "../pdf-pagination/pdf-pagination.service";
import { AuthService } from "../../services/auth.service";
import { HttpPulloutService } from "../../services/http/pullout.service";
import { AdSpendingsCapturePulloutsComponent } from "./pullouts/pullouts.component";

@Component({
  selector: 'wefra-ad-spendings-capture',
  templateUrl: './capture.component.html',
})
export class AdSpendingsCaptureComponent implements OnInit {

  @Input() readonly: boolean = false

  editionRecordId: number | null = null
  editionRecord?: EditionRecordInterface
  record?: AdSpendingRecordInterface
  currentPage: string = "1"
  currentPageIndex: number = 0
  form?: FormGroup
  isAdSpecial: boolean = false
  useWasmuth: boolean = true
  pageArray: number[] = []
  priceRecord?: PriceRecordInterface
  calculatingPrice: boolean = false
  pulloutIdArray: number[] = Array.from({length: 10}, (_, i) => i + 1)
  allowWasmuth: boolean = false
  allowWefra: boolean = false
  userIsAllowedToEditWefraData: boolean = false

  selectedIndexedPrice?: PriceQueryDataInterface

  adSpendings?: AdSpendingRecordInterface[]

  constructor(
    private route: ActivatedRoute,
    @Inject(HttpAdSpendingsService) public adService: HttpAdSpendingsService,
    @Inject(HttpEditionService) private editionService: HttpEditionService,
    @Inject(HttpWasmuthAdCostService) private adCostService: HttpWasmuthAdCostService,
    @Inject(ToastService) private toastService: ToastService,
    @Inject(ModalService) private modalService: ModalService,
    @Inject(LocalStorageService) private localstorageService: LocalStorageService,
    @Inject(StateService) private stateService: StateService,
    @Inject(PdfPaginationService) private pdfPagination: PdfPaginationService,
    @Inject(AuthService) private authService: AuthService,
    @Inject(HttpPulloutService) private pulloutService: HttpPulloutService,
    public fb: FormBuilder,
    private router: Router
  ) {
    this.pdfPagination.getCurrentPageIndexSubject().subscribe(index => {
      // webp images start with 1, so add 1 here
      this.currentPageIndex = index + 1
    })
  }

  ngOnInit(): void {
    this.editionRecordId = parseInt(this.route.snapshot.paramMap.get('editionRecordId')+'')
    const adspendingId = this.route.snapshot.queryParamMap.get('adspendingId')

    if (! this.editionRecordId) {
      this.toastService.showError('Die zugehörige Edition konnte nicht gefunden werden!')
      return
    }
    this.authService.getUserSubject().subscribe(user => {
      if (! user) {
        this.userIsAllowedToEditWefraData = false
        return
      }

      if (this.authService.hasAbility("spa-poweruser")) {
        this.userIsAllowedToEditWefraData = true
      } else {
        this.userIsAllowedToEditWefraData = false
      }
    })
    let that = this

    this.editionService.get(this.editionRecordId).subscribe(edition => {
      this.editionRecord = edition

      this.allowWasmuth = !! (edition.resolved["wasmuth_ratecard_number"] && edition.resolved["wasmuth_medium_id"])
      this.allowWefra = !! (edition.resolved["wefra_ratecard_id"] && edition.resolved["wefra_pubdate_id"])

      if (! this.editionRecord) {
        this.editionRecordId = null
        this.toastService.showError('Die zugehörige Edition konnte nicht gefunden werden!')
        return
      }

      this.loadAdSpendings()

      this.pageArray = [...Array(this.editionRecord.data.pagecount).keys()]

      // preload images
      for(let i = 1; i < this.pageArray.length; i++) {
        let img = new Image();
        img.src = '/files/editions/' + this.editionRecord.data.file_reference + '/' + i + '/large.webp'
      }

      if (adspendingId) {
        this.adService.get(+adspendingId).subscribe(record => {
          if (this.route.snapshot.queryParamMap.get('copy')) {
            if (record.data.id) {
              record.data.id = undefined
            }
          }
          this.record = record
          this.pdfPagination.init(record.data.page+'', edition.data.pagecount, edition.data.prepend_pages,
            edition.data.prepend_adspages, edition.data.append_adspages)

          this.prepareForm()
        }, error => {
          this.toastService.showError('Die erfasste Anzeige konnte nicht gefunden werden!')
          this.record = this.getEmptyRecord()

          this.prepareForm()
        })
      } else {
        let record = this.localstorageService.get('capture_form_record') as AdSpendingRecordInterface
        if (record) {
          if (record.data.edition_id != this.editionRecordId) {
            this.record = this.getEmptyRecord()
          } else {
            this.record = record
          }

          this.stateService.getCurrentCaptureSubject().next(this.record)
          this.pdfPagination.init(record.data.page ? record.data.page + '' : undefined, edition.data.pagecount, edition.data.prepend_pages,
            edition.data.prepend_adspages, edition.data.append_adspages)

        } else {
          this.record = this.getEmptyRecord()
          this.pdfPagination.init(undefined, edition.data.pagecount, edition.data.prepend_pages,
            edition.data.prepend_adspages, edition.data.append_adspages)
        }

        this.prepareForm()
      }
    })
  }

  public openPulloutModal() {
    let that = this
    if (this.editionRecordId) {
      this.pulloutService.list({
        sorting: [ { field: "pullout_id", dir: 'asc' } ],
        paging: { page: 1, size: 100},
        filters: { edition_id: this.editionRecordId }
      }).subscribe(list => {
        let modal = this.modalService.show(AdSpendingsCapturePulloutsComponent, {
          title: "Beihefter dieses Printmediums",
          inputs: {
            records: list.data,
          },
          modalOptions: {
            windowClass: 'alias-select',
          },
          closeBtnText: undefined,
          subscribers: {
            cancelEvent: function (cancel: boolean) {
              modal.close()
            },
            changeEvent: function (cancel: boolean) {
              that.loadAdSpendings()
            }
          }
        })
      }, error => {
        that.toastService.showError("Die Beihefter konnten nicht geladen werden!")
      })
    }
  }

  handleCtrlSpin(amount: number) {
    if (amount < 0 && this.currentPageIndex > 1) this.pdfPagination.setCurrentPageIndex(this.currentPageIndex - 2)
    else if (amount > 0 && this.editionRecord && this.currentPageIndex < this.editionRecord.data.pagecount) this.pdfPagination.setCurrentPageIndex(this.currentPageIndex)
  }

  prepareForm() {

    this.isAdSpecial = !!this.record!.data.is_special
    this.useWasmuth  = !!this.record!.data.use_wasmuth

    this.form = this.fb.group({
      page: [ this.record ? this.record.data.page : undefined, { validators: [ Validators.required, Validators.min(1) ]} ],
      company_id: [ this.record ? this.record.data.company_id : '', { validators: [ Validators.required ]} ],
      product_id: [ this.record ? this.record.data.product_id : '', { validators: [ Validators.required ]} ],
      market_id: [ this.record ? this.record.data.market_id : '', { validators: [ Validators.required ]} ],
      submarket_id: [ this.record ? this.record.data.submarket_id : '' ],
      headline_id: [ this.record ? this.record.data.headline_id : '', { validators: [ Validators.required ]} ],
      is_multi: [ this.record ? this.record.data.is_multi : 0 ],
      is_special: [ this.record ? this.record.data.is_special : 0 ],
      use_wasmuth: [ this.record ? this.record.data.use_wasmuth : true ],
      advertisingform_id: [ this.record ? this.record.data.advertisingform_id : '', { validators: [ Validators.required ]} ],
      wasmuth_ad_id: [ this.record ? this.record.data.wasmuth_ad_id : '' ],
      wasmuth_fkt_form_id: [ this.record ? this.record.data.wasmuth_fkt_form_id : '' ],
      wasmuth_fkt_placement_id: [ this.record ? this.record.data.wasmuth_fkt_placement_id : '' ],
      wasmuth_fkt_color_id: [ this.record ? this.record.data.wasmuth_fkt_color_id : '' ],
      wasmuth_fkt_page_format_code_id: [ this.record ? this.record.data.wasmuth_fkt_page_format_code_id : '' ],
      wasmuth_ad_format_id: [ this.record ? this.record.data.wasmuth_ad_format_id : '' ],
      wasmuth_fkt_cost_type_id: [ this.record ? this.record.data.wasmuth_fkt_cost_type_id : '' ],
      price_value: [ this.record ? this.record.data.price_value : '', { validators: [ Validators.required ]} ],
      wasmuth_fkt_adspecial_type_id: [ this.record ? this.record.data.wasmuth_fkt_adspecial_type_id : '' ],
      wasmuth_adspecial_cost_id: [ this.record ? this.record.data.wasmuth_adspecial_cost_id : '' ],
      wasmuth_fkt_adspecial_cost_type_id: [ this.record ? this.record.data.wasmuth_fkt_adspecial_cost_type_id : '' ],
      wasmuth_adspecial_id: [ this.record ? this.record.data.wasmuth_adspecial_id : '' ],
      wasmuth_ad_cost_id: [ this.record ? this.record.data.wasmuth_ad_cost_id : '' ],
      magazine_id: [ (this.record && this.record.data.magazine_id !== undefined) ? this.record.data.magazine_id : (this.editionRecord ? this.editionRecord.data.magazine_id : '') ],
      category_id: [ (this.record && this.record.data.category_id !== undefined) ? this.record.data.category_id : '' ],
      format_id: [ this.record ? this.record.data.format_id : '' ],
      color_id: [ this.record ? this.record.data.color_id : '' ],
      price_id: [ this.record ? this.record.data.price_id : '' ],
      pullout_id: [ this.record ? this.record.data.pullout_id : ''],
      placement: [ this.record ? this.record.data.placement : '', { validators: [ Validators.required ]}],
    })

    this.form.valueChanges.subscribe((formData: AdSpendingRecordDataInterface) => {
      this.isAdSpecial = !!formData.is_special
      this.useWasmuth = !!formData.use_wasmuth
      Object.assign(this.record!.data, formData)
      this.localstorageService.set('capture_form_record', this.record)
      this.stateService.getCurrentCaptureSubject().next(this.record)
    })

    this.form.controls['price_value']?.valueChanges.subscribe(price => {
      if (this.record) this.record.data.mutual_price = parseInt(price)
    })


    if (! this.allowWasmuth) {
      this.form.controls['use_wasmuth']?.setValue(0)
      this.form.controls['use_wasmuth']?.disable()
    }
  }
  onClearAdvertisingform() {
    this.form?.controls['pullout_id']?.setValue('')
    if (this.record) this.record.data.pullout_id = undefined
  }

  getEmptyRecord(): AdSpendingRecordInterface {
    return {
      data: {
        id: undefined,
        headline_id: undefined,
        magazine_id: this.editionRecord ? this.editionRecord.data.magazine_id : undefined,
        year: undefined,
        month: undefined,
        week: undefined,
        issue: undefined,
        page: undefined,
        volume: undefined,
        price_value: undefined,
        color_id: undefined,
        approved: undefined,
        market_id: undefined,
        submarket_id: undefined,
        advertisingform_id: undefined,
        company_id: undefined,
        product_id: undefined,
        category_id: undefined,
        format_id: undefined,
        price_id: undefined,
        paid: undefined,
        is_multi: 0,
        is_special: 0,
        edition_id: this.editionRecord?.data.id ? this.editionRecord?.data.id : undefined,
        use_wasmuth: 1,
        wasmuth_ad_id: undefined,
        wasmuth_fkt_form_id: undefined,
        wasmuth_fkt_placement_id: undefined,
        wasmuth_fkt_color_id: 4,
        wasmuth_fkt_page_format_code_id: '',
        wasmuth_ad_format_id: '',
        wasmuth_fkt_adspecial_type_id: undefined,
        wasmuth_adspecial_cost_id: undefined,
        wasmuth_fkt_adspecial_cost_type_id: undefined,
        wasmuth_adspecial_id: undefined,
        wasmuth_ad_cost_id: undefined,
        mutual_price: undefined,
        placement: undefined
      },
      resolved: {
        wasmuth_fkt_color_id: {
          data: {
            colour_bit: 8,
            colour_key: 4,
            colour_name: "vierfarbig",
            colour_short_name: "4c"
          },
          resolved: {}
        }
      }
    }
  }
  getFixedFilters(field: string): FiltersInterface {
    // if (field == 'wasmuth_ad_id') {
    //   let r: FiltersInterface= {}
    //   if (this.editionRecord?.data.wasmuth_medium_id) {
    //     r["medium_id"] = this.editionRecord.data.wasmuth_medium_id
    //   }
    //   if (this.editionRecord?.data.wasmuth_ratecard_id) {
    //     r["ratecard_id"] = this.editionRecord.data.wasmuth_ratecard_id
    //   }
    //   if (this.editionRecord?.data.wasmuth_ratecard_number) {
    //     r["ratecard_number"] = this.editionRecord.data.wasmuth_ratecard_number
    //   }
    //
    //   if (this.record?.data.wasmuth_fkt_form_id) {
    //     r["form_key"] = this.record.data.wasmuth_fkt_form_id
    //   }
    //
    //   if (this.record?.data.wasmuth_fkt_placement_id) {
    //     r["placement_key"] = this.record.data.wasmuth_fkt_placement_id
    //   }
    //
    //   if (this.record?.data.wasmuth_fkt_page_format_code_id) {
    //     r["page_format_code"] = this.record.data.wasmuth_fkt_page_format_code_id
    //   }
    //
    //   return r
    // }

    if (field == 'category_id') {
      let r: FiltersInterface = {}
      if (this.record?.data.advertisingform_id) {
        r["advertisingform_id"] = this.record?.data.advertisingform_id
      }
      return r
    }

    if (field == 'price_id') {
      let r: FiltersInterface= {}
      if (this.record?.data.category_id) {
        r["category_id"] = this.record?.data.category_id
      }
      if (this.editionRecord?.data.magazine_id) {
        r["magazine_id"] = this.editionRecord?.data.magazine_id
      }

      return r
    }

    if (field == 'submarket_id') {
      if (this.record?.data.market_id) {
        return { market_id: this.record.data.market_id}
      }
    }

    if (field == 'product_id') {
      if (this.record?.data.company_id) {
        return { company_id: this.record.data.company_id}
      }
    }

    if (field == 'headline_id') {
      if (this.record?.data.product_id) {
        return { product_id: this.record.data.product_id}
      }
    }

    if (field == "wasmuth_ad_format_id") {
      let r: FiltersInterface= {}
      if (this.editionRecord?.data.wasmuth_medium_id) {
        r["medium_id"] = this.editionRecord.data.wasmuth_medium_id
      }
      if (this.editionRecord?.data.wasmuth_ratecard_id) {
        r["ratecard_id"] = this.editionRecord.data.wasmuth_ratecard_id
      }
      if (this.editionRecord?.data.wasmuth_ratecard_number) {
        r["ratecard_number"] = this.editionRecord.data.wasmuth_ratecard_number
      }
      // if (this.record?.data.wasmuth_ad_id) {
      //   r["ads_oid"] = this.record?.data.wasmuth_ad_id
      // }

      return r
    }

    if (field == "wasmuth_ad_cost_id") {
      let r: FiltersInterface= {}

      // custom filter
      if (this.editionRecord?.data.id) {
        r["edition_id"] = this.editionRecord.data.id
      }

      if (this.record?.data.wasmuth_fkt_placement_id) {
        r["adFormatsOid.adsOid.placement_key"] = this.record.data.wasmuth_fkt_placement_id
      }

      // // orientation: "quer"
      //r["adFormatsOid.adsOid.orientation_type_key"] = 3

      if (this.record?.data.wasmuth_fkt_page_format_code_id) {
        r["adFormatsOid.adsOid.page_format_code"] = this.record.data.wasmuth_fkt_page_format_code_id
      }
      if (this.record?.data.wasmuth_fkt_color_id) {
        r["colour_key"] = this.record?.data.wasmuth_fkt_color_id
      }

      return r
    }

    if (field == "wasmuth_adspecial_id") {
      let r: FiltersInterface = {}
      if (this.editionRecord?.data.wasmuth_medium_id) {
        r["medium_id"] = this.editionRecord.data.wasmuth_medium_id
      }
      if (this.editionRecord?.data.wasmuth_ratecard_id) {
        r["ratecard_id"] = this.editionRecord.data.wasmuth_ratecard_id
      }
      if (this.editionRecord?.data.wasmuth_ratecard_number) {
        r["ratecard_number"] = this.editionRecord.data.wasmuth_ratecard_number
      }
      return r
    }

    if (field == "wasmuth_adspecial_cost_id") {
      let r: FiltersInterface= {}
      if (this.record?.data.wasmuth_adspecial_id) {
        r["adspecials_oid"] = this.record?.data.wasmuth_adspecial_id
      }

      return r
    }
    return {}
  }
  onSelectPhmPrice(record?: RecordInterface<RecordDataInterface>) {
    let sum = 0
    const castrecord = record ? record as PriceRecordInterface : undefined

    if (castrecord) {

      const color = this.record?.resolved['color_id']?.data["name"]

      if (!color) {
        this.toastService.showError('Bitte erst Farbe auswählen!')
        return
      }

      this.priceRecord = castrecord

      sum += +castrecord.data['price' + color]
      sum += +castrecord.data['surcharge' + color]

      if (this.record?.data) {
        if (castrecord.resolved["category_id"]) {
          this.record.data.category_id = castrecord.data.category_id
          this.record.resolved["category_id"] = castrecord.resolved["category_id"]
          this.form?.controls["category_id"]?.setValue(castrecord.data.category_id)
        }
        if (castrecord.resolved["format_id"]) {
          this.record.data.format_id = castrecord.data.format_id
          this.record.resolved["format_id"] = castrecord.resolved["format_id"]
          this.form?.controls["format_id"]?.setValue(castrecord.data.format_id)
        }
      }

    } else {
      sum = 0
      if (this.record?.data) {
        this.record.data.category_id = undefined
        delete this.record.resolved["category_id"]
        this.form?.controls["category_id"]?.setValue(undefined)

        this.record.data.format_id = undefined
        delete this.record.resolved["format_id"]
        this.form?.controls["format_id"]?.setValue(undefined)
      }
    }

    this.setPrice(sum)
  }

  openPreviousPricePopup() {
    if (! this.record || ! this.editionRecord) return
    let that = this
    let fixedFilters: FiltersInterface = {}
    let limitToFields: string[] = [
      'product_id', 'company_id', 'headline_id', 'year', 'month', 'issue', 'page', 'price_value', 'mutual_price', 'pullout_id', 'wasmuth_ad_id', 'advertisingform_id'
    ]
    let titleSlugs: string[] = []
    let typeSlug = ''
    const magazineName = this.editionRecord?.resolved["magazine_id"]?.data["name"]
    if (magazineName) titleSlugs.push(magazineName)

    if (this.editionRecord.data.magazine_id) {
      fixedFilters["magazine_id"] = this.editionRecord.data.magazine_id + ''
    }

    if (this.record?.data.is_special) { // create filter for adspecial
      // typeSlug = 'AdSpecial'
      // fixedFilters["is_special"] = 1
      if (this.record.data.wasmuth_adspecial_id != null) {
        // fixedFilters["wasmuth_adspecial_id"] = this.record.data.wasmuth_adspecial_id
        // TODO: show plausible value here
        // const formatName = this.record.resolved["wasmuth_adspecial_id"]?.data["page_format_code"]
        // if (formatName) titleSlugs.push(formatName)

      } else {
        this.toastService.showError('Bitte erst Anzeigekosten wählen!')
        return
      }
    } else if (this.record?.data.use_wasmuth) { // create filter for wasmuth sourced adspendings

      // fixedFilters["use_wasmuth"] = 1
      // fixedFilters["is_special"] = 0
      // typeSlug = 'Wasmuth'

      if (this.record.data.wasmuth_fkt_page_format_code_id != null) {
        fixedFilters["wasmuth_fkt_page_format_code_id"] = this.record.data.wasmuth_fkt_page_format_code_id
        const formatName = this.record.resolved["wasmuth_fkt_page_format_code_id"]?.data["page_format_code"]
        if (formatName) titleSlugs.push(formatName)
      } else {
        this.toastService.showError('Bitte erst Anzeigekosten wählen!')
        return
      }

      // if (this.record.data.wasmuth_fkt_placement_id != null) {
      //   fixedFilters["wasmuth_fkt_placement_id"] = this.record.data.wasmuth_fkt_placement_id
      //   const placementName = this.record.resolved["wasmuth_fkt_placement_id"]?.data["placement_name"]
      //   if (placementName) titleSlugs.push(placementName)
      // }

      if (this.record.data.wasmuth_fkt_color_id != null) {
        fixedFilters["wasmuth_fkt_color_id"] = this.record.data.wasmuth_fkt_color_id
        const colorName = this.record.resolved["wasmuth_fkt_color_id"]?.data["colour_short_name"]
        if (colorName) titleSlugs.push(colorName)
      } else {
        this.toastService.showError('Bitte erst Farbigkeit auswählen!')
        return
      }

    } else { // create filter for wefra sourced adspendings
      typeSlug = 'WEFRA'
      fixedFilters["use_wasmuth"] = 0
      fixedFilters["is_special"] = 0

      if (this.record.data.color_id != null) {
        fixedFilters["color_id"] = this.record.data.color_id
        const colorName = this.record.resolved["color_id"]?.data["name"]
        if (colorName) titleSlugs.push(colorName)
      }

      if (this.record.data.category_id != null) {
        fixedFilters["category_id"] = this.record.data.category_id
        const catName = this.record.resolved["category_id"]?.data["name"]
        if (catName) titleSlugs.push(catName)
      }

      if (this.record.data.format_id != null) {
        fixedFilters["format_id"] = this.record.data.format_id
        const formatName = this.record.resolved["format_id"]?.data["name"]
        if (formatName) titleSlugs.push(formatName)
      }
    }

    fixedFilters["placement"] = this.record.data.placement + ''
    titleSlugs.push(fixedFilters["placement"])
    const title = "Preis aus vorheriger Erfassung wählen (" + titleSlugs.join(', ') + ' - ' + typeSlug + ')'

    let modal = this.modalService.show(CrudGridComponent, {
      inputs: {
        modelConfig: ModelConfigFactory.getConfig('ad_spendings'),
        api: this.adService,
        selectOnly: true,
        fixedFilters: fixedFilters,
        allowMultiSelect: false,
        forceFields: [ 'format_id', 'wasmuth_fkt_placement_id' ],
        limitToFields: limitToFields
      },
      closeBtnText: undefined,
      title: title,
      modalOptions: {
        windowClass: 'grid-modal',
      },
      subscribers: {
        selectRecordEvent: function(record: RecordInterface<RecordDataInterface>) {
          const cr = record as AdSpendingRecordInterface
          if (cr.data.mutual_price) {
            that.setPrice(cr.data.mutual_price)
          } else if (cr.data.price_value) {
            that.setPrice(cr.data.price_value)
          }
          modal.close()
        }
      }
    })
  }

  onSelectIndexedPrice(data: PriceQueryDataInterface) {
    this.selectedIndexedPrice = data

    if (data.placement) {
      this.form?.controls['placement']?.setValue(data.placement)
    }

    if (data.type == "wa") {
      this.onSelectAdCost(data)
    } else {
      this.onSelectAdspecialCost(data)
    }
    let domEl = document.querySelectorAll('.cost-final .form-control')[0] as HTMLInputElement
    domEl.scrollIntoView({
      behavior: 'smooth',
      block: "center",
      inline: "nearest"
    });
  }

  onSelectAdCost(data: PriceQueryDataInterface) {
    this.form?.controls['wasmuth_fkt_color_id']?.setValue(data.colour_key)
    this.form?.controls['wasmuth_fkt_placement_id']?.setValue(data.placement_key)
    this.form?.controls['wasmuth_fkt_page_format_code_id']?.setValue(data.page_format_code)
    this.form?.controls['wasmuth_ad_id']?.setValue(data.ads_oid)

    this.form?.controls['wasmuth_fkt_cost_type_id']?.setValue(data.cost_type_key)

    this.form?.controls['wasmuth_ad_format_id']?.setValue(data.wasmuth_ad_format_id)
    this.form?.controls['wasmuth_fkt_form_id']?.setValue(data.wasmuth_fkt_form_id)
    this.form?.controls['wasmuth_ad_cost_id']?.setValue(data.wasmuth_adcost_id)

    this.form?.controls['is_special']?.setValue(0)

    this.form?.controls['wasmuth_fkt_adspecial_type_id']?.setValue(null)
    this.form?.controls['wasmuth_fkt_adspecial_cost_type_id']?.setValue(null)
    this.form?.controls['wasmuth_adspecial_id']?.setValue(null)
    this.form?.controls['wasmuth_adspecial_cost_id']?.setValue(null)

    this.setPrice(data.cost_value)
  }

  onSelectAdspecialCost(data: PriceQueryDataInterface) {
    this.form?.controls['wasmuth_fkt_adspecial_type_id']?.setValue(data.adspecial_type_key)
    this.form?.controls['wasmuth_fkt_adspecial_cost_type_id']?.setValue(data.adspecial_cost_type_key)
    this.form?.controls['wasmuth_adspecial_id']?.setValue(data.adspecial_oid)
    this.form?.controls['wasmuth_adspecial_cost_id']?.setValue(data.adspecial_cost_oid)

    this.form?.controls['is_special']?.setValue(1)

    this.form?.controls['wasmuth_fkt_color_id']?.setValue(null)
    this.form?.controls['wasmuth_fkt_placement_id']?.setValue(null)
    this.form?.controls['wasmuth_fkt_page_format_code_id']?.setValue(null)
    this.form?.controls['wasmuth_ad_id']?.setValue(null)
    this.form?.controls['wasmuth_fkt_cost_type_id']?.setValue(null)
    this.form?.controls['wasmuth_ad_format_id']?.setValue(null)
    this.form?.controls['wasmuth_fkt_form_id']?.setValue(null)
    this.form?.controls['wasmuth_ad_cost_id']?.setValue(null)


    this.setPrice(data.cost_value)
  }

  openSearchGridModal() {
    if (! this.editionRecord) return
    let that = this
    let modal = this.modalService.show(AdSpendingsCaptureSearchGridComponent, {
      inputs: {
        ratecardId: this.editionRecord.data.wasmuth_ratecard_id,
        ratecardNumber: this.editionRecord.data.wasmuth_ratecard_number
      },
      subscribers: {
        selectPriceEvent: function(data: PriceQueryDataInterface) {
          that.onSelectIndexedPrice(data)
          modal.close()
        }
      },
      modalOptions: {
        windowClass: 'grid-modal',
      },
      title: 'Anzeigekosten wählen',
    })
  }

  setPrice(price: number) {
    this.form?.controls['price_value']?.setValue(Math.round(price))
    if (this.record) this.record.data.mutual_price = Math.round(price)

    let domEl = document.querySelectorAll('.cost-final .form-control')[0] as HTMLInputElement
    domEl.classList.add('flash')
    setTimeout(()=> {domEl.classList.remove('flash')}, 1500)

    this.calculatingPrice = false
  }

  onSaveAndNew() {
    if (! this.form) return
    this.onSubmit(this.form.value, this.afterSaveNewRecord)
  }

  onSaveAndCopy() {
    if (! this.form) return
    this.onSubmit(this.form.value, this.afterSaveCopyRecord)
  }

  onCancel() {
    this.record = this.getEmptyRecord()
    this.form?.reset(this.getEmptyRecord())
    this.form?.controls['use_wasmuth']?.setValue(1)
    this.localstorageService.remove('capture_form_record')
    this.stateService.getCurrentCaptureSubject().next(undefined)
  }

  onCancelAndBack() {
    this.onCancel()
    this.router.navigate(['/ad-spendings/editions'])
  }

  onSubmit(formData: AdSpendingRecordDataInterface, afterSaveCallback?: () => void) {
    if (! this.form?.valid) {
      this.form?.markAllAsTouched()
      return
    }

    if (! this.record) return
    Object.assign(this.record!.data, formData)

    let date, issue
    try {
      if (this.allowWasmuth) {
        date = new Date(this.editionRecord?.resolved?.["wasmuth_publication_date"]?.data["publication_date"])
        issue = this.editionRecord?.resolved?.["wasmuth_publication_date"]?.data["edition_name"]
      } else {
        date = new Date(this.editionRecord?.resolved?.["wefra_pubdate_id"]?.data["pubdate"])
        issue = this.editionRecord?.resolved?.["wefra_pubdate_id"]?.data["edition_number"]
      }
      if (issue) {
        const split = issue.split('/')
        if (split.length == 2) {
          issue = split[0]
        }
      } else {
        this.toastService.showError('Konnte Ausgabennummer nicht ermitteln!')
        issue = 0
      }
    } catch(e) {
      this.toastService.showError('Konnte Datum nicht ermitteln!')
      date = new Date()
      issue = 0
    }

    this.record.data.magazine_id = this.editionRecord ? this.editionRecord.data.magazine_id : undefined
    this.record.data.year = date.getFullYear()
    this.record.data.month = date.getMonth() + 1
    this.record.data.day = date.getDate()
    this.record.data.week = getWeekOfYear(date)
    this.record.data.issue = issue

    let that = this
    if (this.record.data.advertisingform_id == 3) {
      this.adService.findPullOutSiblings(this.record.data).subscribe(records => {
        if (! records || ! records.length) {
          this.saveRecord(afterSaveCallback)
          return
        }
        let modal = this.modalService.show(AdSpendingsPullOutQuestionComponent, {
          inputs: {
            records: records,
          },
          subscribers: {
            answerEvent: function(answer: boolean) {
              if (answer) {
                that.saveRecord(afterSaveCallback)
                modal.close()
              } else {
                modal.close()
              }
            }
          },
          title: 'Beihefter',
        })
      })
    } else if (this.record.data.is_multi) {
      this.adService.findIsMultiSiblings(this.record.data).subscribe(records => {
        if (! records || ! records.length) {
          this.saveRecord(afterSaveCallback)
          return
        }
        let modal = this.modalService.show(AdSpendingsIsMultiQuestionComponent, {
          inputs: {
            records: records,
          },
          subscribers: {
            answerEvent: function(answer: boolean) {
              if (answer) {
                that.saveRecord(afterSaveCallback)
                modal.close()
              } else {
                modal.close()
              }
            }
          },
          title: 'Multianzeige',
        })
      })
    } else {
      this.saveRecord(afterSaveCallback)
    }
  }

  saveRecord(afterSaveCallback?: () => void) {
    if (this.record!.data.id) {
      this.adService.update(this.record!.data).subscribe(adspending => {
        this.record = adspending
        this.form?.reset(this.record.data)
        this.toastService.showSuccess("Anzeige wurde erfolgreich aktualisiert!")
        this.localstorageService.remove('capture_form_record')
        this.stateService.getCurrentCaptureSubject().next(undefined)
        if (afterSaveCallback) {
          afterSaveCallback.call(this)
        } else {
          this.router.navigate([ '.' ], {relativeTo: this.route, queryParams: {}})
          this.loadAdSpendings()
        }
      }, error => {
        this.toastService.showError("Fehler beim Aktualisieren der Anzeige!")
      })
    } else {
      this.adService.create(this.record!.data).subscribe(adspending => {
        this.record = adspending
        this.form?.reset(this.record.data)
        this.toastService.showSuccess("Anzeige wurde erfolgreich angelegt!")
        this.localstorageService.remove('capture_form_record')
        if (afterSaveCallback) {
          afterSaveCallback.call(this)
        } else {
          this.router.navigate(['.'], { relativeTo: this.route, queryParams: {}})
          this.loadAdSpendings()
        }
      }, error => {
        this.toastService.showApiError(error,"Fehler beim Anlegen der Anzeige!")
      })
    }
  }

  onDeleteAdSpending(record: AdSpendingRecordInterface) {
    let that = this
    let modal = this.modalService.show(CrudGridDeleteComponent, {
      subscribers: {
        decisionEvent: function(decision: boolean) {
          if (decision) {
            that.adService.delete(record).subscribe(response => {
              that.loadAdSpendings()
              that.toastService.showSuccess('Datensatz wurde erfolgreich gelöscht!')
            }, error => {
              that.toastService.showError('Datensatz konnte nicht gelöscht werden!')
            })
          }
          modal.close()
        }
      }
    })
  }

  afterSaveNewRecord() {
    this.record = this.getEmptyRecord()
    this.form?.reset(this.getEmptyRecord())
    this.form?.controls['use_wasmuth']?.setValue(1)
    this.localstorageService.remove('capture_form_record')
    this.stateService.getCurrentCaptureSubject().next(undefined)
    this.selectedIndexedPrice = undefined

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.loadAdSpendings()
  }

  afterSaveCopyRecord() {
    if (! this.record) return
    this.localstorageService.remove('capture_form_record')
    if (this.record.data.edition_id) {
      window.location.href = 'https://' + window.location.host + '/ad-spendings/capture/' + this.record.data.edition_id + '?adspendingId=' + this.record.data.id + '&copy=true'
    }
  }

  onSelectProduct(record: RecordInterface<RecordDataInterface>) {
    const castrecord = record as ProductRecordInterface

    if (! castrecord) return

    if (this.record?.data) {
      if (castrecord.resolved["company_id"]) {
        this.record.data.company_id = castrecord.data.company_id
        this.record.resolved["company_id"] = castrecord.resolved["company_id"]
        this.form?.controls["company_id"]?.setValue(castrecord.data.company_id)
      }
      if (castrecord.resolved["market_id"]) {
        this.record.data.market_id = castrecord.data.market_id
        this.record.resolved["market_id"] = castrecord.resolved["market_id"]
        this.form?.controls["market_id"]?.setValue(castrecord.data.market_id)
      }
      if (castrecord.resolved["submarket_id"]) {
        this.record.data.submarket_id = castrecord.data.submarket_id
        this.record.resolved["submarket_id"] = castrecord.resolved["submarket_id"]
        this.form?.controls["submarket_id"]?.setValue(castrecord.data.submarket_id)
      }
    }
  }

  onSelectSubmarket(record: RecordInterface<RecordDataInterface>) {
    const castrecord = record as SubmarketRecordInterface
    if (! castrecord) return
    if (this.record?.data) {
      if (castrecord.resolved["market_id"]) {
        this.record.data.market_id = castrecord.data.market_id
        this.record.resolved["market_id"] = castrecord.resolved["market_id"]
        this.form?.controls["market_id"]?.setValue(castrecord.data.market_id)
      }
    }
  }

  setRecordPageToViewPage() {
    if (this.form) {
      this.form.controls['page']!.setValue(this.pdfPagination.getCurrentViewPageSubject().getValue())
    }
  }

  loadAdSpendings() {
    if (! this.editionRecord) return
    this.adService.list({
      sorting: [{field: "page", dir: 'asc'}],
      paging: { page: 1, size: 1000 },
      filters: { edition_id: this.editionRecord.data.id! }
    }).subscribe(list => {
      this.adSpendings = list.data
    })
  }

  protected readonly ModelConfigFactory = ModelConfigFactory;
  protected readonly Array = Array;
}
