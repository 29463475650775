<h2>Anzeige erfassen</h2>

<div class="row" *ngIf="editionRecord; else noEditionRecord">
  <ng-container *ngIf="editionRecord.data.capture_state > 0; else notCaptured">
  <div class="col-4">

    <wefra-pdf-pagination />

    <div class="image-container mb-3">
      <ng-container *ngFor="let pageIndex of pageArray">
        <pinch-zoom *ngIf="pageIndex == (currentPageIndex - 1)" wheelCtrl (onSpin)="handleCtrlSpin($event)" [disableZoomControl]="'disable'">
          <img [src]="'/files/editions/' + editionRecord.data.file_reference + '/' + (currentPageIndex - 1) + '/large.webp'">
        </pinch-zoom>
        <ng-container *ngIf="pageIndex == (currentPageIndex - 1) && currentPageIndex < editionRecord.data.pagecount">
          <hr />
          <pinch-zoom wheelCtrl (onSpin)="handleCtrlSpin($event)" [disableZoomControl]="'disable'">
            <img [src]="'/files/editions/' + editionRecord.data.file_reference + '/' + (currentPageIndex) + '/large.webp'">
          </pinch-zoom>
        </ng-container>
      </ng-container>
    </div>

    <wefra-pdf-pagination />

  </div>
  <div class="col-8">
    <div class="row ">
      <div class="col-12">
        <h3 class="mb-3 mt-5">Printmedium</h3>

<!--        <p *ngIf="allowWasmuth && allowWefra"></p>-->
<!--        <p *ngIf="allowWasmuth && ! allowWefra"></p>-->
        <p *ngIf="(! allowWasmuth) && allowWefra">Diese Ausgabe kann nicht mit Wasmuth-Daten erfasst werden!</p>

        <ng-container *ngIf="allowWasmuth; else magazineData">
          <dl class="edition" *ngIf="editionRecord.resolved['wasmuth_medium_id']">
            <dt>Medium</dt><dd>{{ editionRecord.resolved["wasmuth_medium_id"]!.data["name1"] }}</dd>
            <dt>Publisher</dt><dd>{{ editionRecord.resolved["wasmuth_publisher_id"]!.data["publisher_name"] }}</dd>
            <dt>Nummer</dt><dd>{{ editionRecord.resolved["wasmuth_publication_date"]!.data["edition_name"] }}</dd>
            <dt>Auflage</dt><dd>{{ editionRecord.data.circulation_print }}</dd>
          </dl>
          <dl class="edition" *ngIf="editionRecord.resolved['wasmuth_ratecard_number']">
            <dt>Gültig ab</dt><dd>{{ editionRecord.resolved["wasmuth_ratecard_number"]!.data["valid_since"] | mydate }}</dd>
            <ng-container *ngIf='editionRecord.resolved["wasmuth_ratecard_number"]!.data["valid_till"]; else notill'>
              <dt>Gültig bis</dt><dd>{{ editionRecord.resolved["wasmuth_ratecard_number"]!.data["valid_till"] | mydate }}</dd>
            </ng-container>
            <ng-template #notill>
              <dt>Gültig bis</dt><dd>noch nicht definiert</dd>
            </ng-template>
          </dl>
        </ng-container>
        <ng-template #magazineData>
          <dl class="edition" *ngIf="editionRecord.resolved['magazine_id']">
            <dt>Magazin</dt><dd>{{ editionRecord.resolved["magazine_id"]!.data["name"] }}</dd>
          </dl>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="pullout-link" (click)="openPulloutModal()">
          <i-bs name="collection"></i-bs><strong>Beihefter dieses Printmediums</strong>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="! readonly">
      <div class="col-12">
        <form *ngIf="form" novalidate [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
          <div class="row">
            <h3 class="col-12 mt-5 mb-3">Metainformationen</h3>

            <div class="col-2">
              <label>Seite<span class="pointer" (click)="setRecordPageToViewPage()" ngbTooltip="Angezeigte Seite wählen">
                <i-bs name="bookmark-check" /></span>
              </label>
              <input type="text" class="form-control" formControlName="page" />
              <form-field-error text="Seite" path="page"></form-field-error>
            </div>

            <div class="col-2">
              <label>Multi</label>
              <wefra-switch-form-field formControlName="is_multi" />
            </div>

            <div class="col-4">
              <label>Firma</label>
              <wefra-constraint-form-field [record]="record" formControlName="company_id"
                [fieldConfig]="ModelConfigFactory.getFieldConfig('ad_spendings', 'company_id')"
                [gridShowAddRecordButton]="true"
              ></wefra-constraint-form-field>
              <form-field-error text="Firma" path="company_id"></form-field-error>
            </div>

            <div class="col-4">
              <label>Produkt</label>
              <wefra-constraint-form-field [record]="record" formControlName="product_id"
                [fieldConfig]="ModelConfigFactory.getFieldConfig('ad_spendings', 'product_id')"
                [fixedFilters]="getFixedFilters('product_id')"
                (selectRecord)="onSelectProduct($event)"
                [gridShowAddRecordButton]="true"
              ></wefra-constraint-form-field>
              <form-field-error text="Produkt" path="product_id"></form-field-error>
            </div>

            <div class="col-4">
              <label>Branche</label>
              <wefra-constraint-form-field [record]="record" formControlName="market_id"
                [fieldConfig]="ModelConfigFactory.getFieldConfig('ad_spendings', 'market_id')"
                [gridShowAddRecordButton]="true"
              ></wefra-constraint-form-field>
              <form-field-error text="Branche" path="market_id"></form-field-error>
            </div>

            <div class="col-4">
              <label>Unterbranche</label>
              <wefra-constraint-form-field [record]="record" formControlName="submarket_id"
                [fieldConfig]="ModelConfigFactory.getFieldConfig('ad_spendings', 'submarket_id')"
                [fixedFilters]="getFixedFilters('submarket_id')"
                (selectRecord)="onSelectSubmarket($event)"
                [gridShowAddRecordButton]="true"
              ></wefra-constraint-form-field>
              <form-field-error text="Unterbranche" path="submarket_id"></form-field-error>
            </div>

            <div class="col-4">
              <label>Motiv</label>
              <wefra-constraint-form-field [record]="record" formControlName="headline_id"
                [fieldConfig]="ModelConfigFactory.getFieldConfig('ad_spendings', 'headline_id')"
                [fixedFilters]="getFixedFilters('headline_id')"
                [gridShowAddRecordButton]="true"
              ></wefra-constraint-form-field>
              <form-field-error text="Motiv" path="headline_id"></form-field-error>
            </div>

            <div class="col-4">
              <label>Werbeform</label>
              <wefra-constraint-form-field [record]="record" formControlName="advertisingform_id"
                [fieldConfig]="ModelConfigFactory.getFieldConfig('ad_spendings', 'advertisingform_id')"
                [gridShowAddRecordButton]="true"
                (clear)="onClearAdvertisingform()"
              ></wefra-constraint-form-field>
              <form-field-error text="Advertising Form" path="advertisingform_id"></form-field-error>
            </div>

            <div class="col-4">
              <label>Beihefter des Printmediums (Lfd. Nr.)</label>
              <select class="form-control" formControlName="pullout_id" [disableControl]="! record || (record.data.advertisingform_id != 3)">
                <option value="0"></option>
                <ng-container *ngFor="let i of pulloutIdArray">
                  <option value="{{i}}">{{i}}</option>
                </ng-container>
              </select>
<!--              <form-field-error text="Advertising Form" path="advertisingform_id"></form-field-error>-->
            </div>



          </div>

          <div class="row" *ngIf="userIsAllowedToEditWefraData">
            <div class="col-10 mt-5 mb-3">
              <wefra-switch-form-field *ngIf="allowWasmuth" class="float-start me-3" formControlName="use_wasmuth" />
              <h3 class="float-start" *ngIf="! useWasmuth">Anzeige mit WEFRA Daten</h3>
              <h3 class="float-start" *ngIf="useWasmuth">Anzeige mit Wasmuth Daten</h3>
            </div>
          </div>

          <ng-container *ngIf="useWasmuth; else nowasmuth">
            <div class="row">
              <div class="col-12">
                <label>Kosten</label>
                <button type="button" class="btn btn-green mt-2 mb-2" (click)="openSearchGridModal()">Anzeigekosten wählen</button>
              </div>
            </div>
          </ng-container>

          <ng-template #nowasmuth>
            <div class="row">
              <div class="col-4">
                <label>Kategorie<span class="tablename"> (phm_categories)</span></label>
                <wefra-constraint-form-field [record]="record" formControlName="category_id"
                   [fieldConfig]="ModelConfigFactory.getFieldConfig('ad_spendings', 'category_id')"
                   [fixedFilters]="getFixedFilters('category_id')"
                >
                </wefra-constraint-form-field>
              </div>
              <div class="col-4">
                <label>Format<span class="tablename"> (phm_formats)</span></label>
                <wefra-constraint-form-field [record]="record" formControlName="format_id"
                   [fieldConfig]="ModelConfigFactory.getFieldConfig('ad_spendings', 'format_id')"
                   [fixedFilters]="getFixedFilters('format_id')"
                >
                </wefra-constraint-form-field>
              </div>
              <div class="col-4">
                <label>Farbe<span class="tablename"> (phm_colors)</span></label>
                <wefra-constraint-form-field [record]="record" formControlName="color_id"
                   [fieldConfig]="ModelConfigFactory.getFieldConfig('ad_spendings', 'color_id')"
                   [fixedFilters]="getFixedFilters('color_id')"
                >
                </wefra-constraint-form-field>
              </div>
              <div class="col-4">
                <label>Preistabelle<span class="tablename"> (phm_prices)</span></label>
                <wefra-multi-form-field
                   [selectedRecordTemplate]="ptTemplate"
                   [record]="record" formControlName="price_id"
                   [fieldConfig]="ModelConfigFactory.getFieldConfig('ad_spendings', 'price_id')"
                   [fixedFilters]="getFixedFilters('price_id')"
                   (selectRecord)="onSelectPhmPrice($event)"
                >
                </wefra-multi-form-field>

                <ng-template #ptTemplate>
                  <ng-container *ngIf="priceRecord?.data">
                    <dl>
                      <dt>Preis SW</dt><dd>{{ priceRecord?.data?.priceSW }}</dd>
                      <dt>Preis 2c</dt><dd>{{ priceRecord?.data?.price2c }}</dd>
                      <dt>Preis 3c</dt><dd>{{ priceRecord?.data?.price3c }}</dd>
                      <dt>Preis 4c</dt><dd>{{ priceRecord?.data?.price4c }}</dd>

                    </dl>
                  </ng-container>
                </ng-template>


              </div>
            </div>
          </ng-template>

          <div class="row">
            <div class="col-4 cost-final">
              <label>Kosten Final</label>
              <div class="function-field-wrap">
                <input type="number" [readonly]="calculatingPrice" class="form-control" formControlName="price_value" />
                <i-bs *ngIf="calculatingPrice" name="arrow-clockwise" />
                <i-bs name="coin" ngbTooltip="Preis aus vorheriger Erfassung abfragen" (click)="openPreviousPricePopup()" />
              </div>
              <form-field-error text="Kosten Final" path="price_value"></form-field-error>
            </div>
            <div class="col-4 placement">
              <label>Platzierung/Werbemittel/Format/Umfang</label>
              <div class="function-field-wrap">
                <input type="text" class="form-control" formControlName="placement" />
              </div>
              <form-field-error text="Platzierung/Werbemittel/Format/Umfang" path="placement"></form-field-error>
            </div>
<!--            <div class="col-4 mutual_price">-->
<!--              <label>Gemeinsamer Preis</label>-->
<!--              <div class="function-field-wrap">-->
<!--                <input type="text" class="form-control" formControlName="mutual_price" />-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="d-flex mt-5">
            <div class="pe-3">
              <button type="submit" class="btn btn-green">Speichern</button>
            </div>
            <div class="pe-3">
              <button type="button" (click)="onSaveAndNew()" class="btn btn-green">Speichern und neu</button>
            </div>
            <div class="pe-3">
              <button type="button" (click)="onSaveAndCopy()" class="btn btn-green">Speichern und duplizieren</button>
            </div>
            <div class="flex-grow-1">
              <div class="ps-3 float-end"><button type="button" (click)="onCancel()" class="btn btn-red">Abbrechen</button></div>
              <button type="button" (click)="onCancelAndBack()" class="btn btn-red float-end">Abbrechen und Zurück zu Printmedien</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-12">
      <wefra-ad-spending-table *ngIf="adSpendings" class="d-block mt-5"
        [readonly]="readonly"
        [adSpendings]="adSpendings"
        (deleteRecord)="this.onDeleteAdSpending($event)"
      ></wefra-ad-spending-table>
    </div>
  </div>

  </ng-container>
  <ng-template #notCaptured>
    <p>Es muss erst das Printmedium erfasst werden, bevor Anzeigen erfasst werden können.</p>
    <p><a class="btn btn-green" [routerLink]="['/ad-spendings/edition/' + editionRecord.data.id ]">Zur Erfassung des Printmediums</a></p>
  </ng-template>
</div>

<ng-template #noEditionRecord>
  <p>Keine Edition geladen, bitte über grid aufrufen!</p>
</ng-template>


