import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModelConfigurationFieldInterface, RecordDataInterface, RecordInterface } from "../models/main";
import { HttpServiceFactory } from "../services/http/factory";
import { Subject } from "rxjs";
import {
  EditGridComponentFieldCoordinatesInterface,
  EditGridComponentUpdateResponseInterface
} from "./edit-grid.component";

@Component({
  selector: 'wefra-edit-grid-field-renderer',
  templateUrl: './field-renderer.html',
})
export class EditGridFieldRendererComponent<T extends RecordInterface<K>, K extends RecordDataInterface> implements OnInit {

  @Input({ required: true }) public record?: T
  @Input({ required: true }) public fieldConfig?: ModelConfigurationFieldInterface
  @Input() clickFieldSubject?: Subject<EditGridComponentFieldCoordinatesInterface>
  @Input() saveResponseSubject?: Subject<EditGridComponentUpdateResponseInterface>

  @Output("updateRecord") updateRecordEvent = new EventEmitter<T>()

  @ViewChild('input') input?: ElementRef<HTMLInputElement>

  editing: boolean = false
  updating: boolean = false
  updateSuccess?: boolean = undefined
  value: string | number = ""

  constructor(
    @Inject(HttpServiceFactory) protected httpServiceFactory: HttpServiceFactory,
  ) { }

  onFocusOut(e: any) {
    this.editing = false
  }

  onKeyUp(e: KeyboardEvent) {
    if (! this.fieldConfig || ! this.record) return

    if (e.key === "Escape") {
      e.stopPropagation()
      this.editing = false
    } else if (e.key === "Enter") {
      const input = e.target as HTMLInputElement
      if (this.record.data[this.fieldConfig.prop] != input.value) {
        let copyrecord = JSON.parse(JSON.stringify(this.record))
            copyrecord.data[this.fieldConfig.prop] = input.value

        this.updateRecordEvent.emit(copyrecord)
        this.updating = true
      }

      this.editing = false
    }
  }

  ngOnInit(): void {
    if (! (this.record && this.fieldConfig && this.fieldConfig.prop)) return

    this.value = this.record!.data[this.fieldConfig!.prop]
    let that = this

    if (this.clickFieldSubject) {
      this.clickFieldSubject.subscribe(field => {
        if (this.record && this.record.data.id == field.id && this.fieldConfig?.prop == field.prop) {
          this.editing = true
          setTimeout( () => { that.input?.nativeElement.focus() }, 200)
        } else {
          this.editing = false
        }
      })
    }

    if (this.saveResponseSubject) {
      this.saveResponseSubject.subscribe(response => {
        if (this.record && this.record.data.id == response.field.id && this.fieldConfig?.prop == response.field.prop) {
          this.updating = false
          this.editing = false
          this.updateSuccess = response.success
          this.record = response.record as T
          setTimeout(() => {
            that.updateSuccess = undefined
          }, 2000)
        }
      }, () => {
        this.updating = false
        this.editing = false
        this.updateSuccess = false
        setTimeout(() => {
          that.updateSuccess = undefined
        }, 2000)
      })
    }
  }
}
