import {
  GridActionsInterface,
  ModelConfigurationInterface,
  RecordDataInterface,
  RecordInterface
} from "../models/main";
import { HttpAbstractRecordService } from "../services/http/record.service.abstract";
import { Directive, inject, OnInit } from "@angular/core";
import { ToastService } from "../services/toast.service";
import { ModalService } from "../services/modal.service";
import { AbstractRecordsViewComponent } from "./view/view.component";
import { Subject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { GenericFormComponent, SaveGenericRecordEventInterface } from "../form/generic-form/generic-form.component";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../services/auth.service";
import { ModelConfigFactory } from "../models/factory";

@Directive()
export abstract class AbstractRecordComponent<T extends RecordInterface<K>, K extends RecordDataInterface> implements OnInit {
  abstract api: HttpAbstractRecordService<T, K>
  abstract modelConfig: ModelConfigurationInterface
  records?: T[]
  isLoading: boolean = false
  toastService: ToastService = inject(ToastService)
  modalService: ModalService = inject(ModalService)
  additionalGridActions?: GridActionsInterface[]
  gridActions?: GridActionsInterface[]
  gridShowAddRecordButton: boolean = false
  gridPersistentStateKey?: string = "generic"
  gridReloadSubject: Subject<boolean> = new Subject<boolean>()
  openedModal?: NgbModalRef
  private authService: AuthService
  private route: ActivatedRoute
  constructor(
  ) {
    this.authService = inject(AuthService)
    this.route = inject(ActivatedRoute)
  }

  onEditRecord(record: T) {
    this.openEditorModal(record)
  }

  onNewRecord(preselection?: K) {
    this.openEditorModal(this.api.getEmptyRecord(preselection))
  }

  openEditorModal(record: T) {
    if (this.openedModal) return
    let that = this
    this.openedModal = this.modalService.show(GenericFormComponent, {
      title: this.modelConfig.titleSingular + (record.data.id ? ' bearbeiten' + ( ModelConfigFactory.getIdFieldProperty(this.modelConfig.serviceSlug) ? (' (' + record.data[ModelConfigFactory.getIdFieldProperty(this.modelConfig.serviceSlug)] + ')' ) : '') : ' erstellen'),
      inputs: {
        record: record,
        modelConfig: this.modelConfig,
      },
      subscribers: {
        updateRecordEvent: (record: SaveGenericRecordEventInterface<K>) => {
          this.saveRecord(record)
        },
        closeClickEvent: () => { that.closeModal() },
      }
    })
    this.openedModal.dismissed.subscribe(() => { that.openedModal = undefined })
    this.openedModal.closed.subscribe(() => { that.openedModal = undefined })
  }

  saveRecord(o: SaveGenericRecordEventInterface<K>) {
    this.isLoading = true
    if (o.record.id === null) {
      this.api.create(o.record).subscribe(response => {
        this.gridReloadSubject.next(true)
        this.toastService.showSuccess('Datensatz wurde erfolgreich angelegt!')
      }, error => {
        this.isLoading = false
        this.toastService.showApiError(error,'Datensatz konnte nicht angelegt werden!')
      }, () => {
        if (o.closeEditor) this.closeModal()
        this.gridReloadSubject.next(true)
      })
    } else {
      this.api.update(o.record).subscribe(response => {
        this.toastService.showSuccess('Datensatz wurde erfolgreich aktualisiert!')
      }, error => {
        this.isLoading = false
        this.toastService.showApiError(error,'Datensatz konnte nicht aktualisiert werden!')
      }, () => {
        if (o.closeEditor) this.closeModal()
        this.gridReloadSubject.next(true)
      })
    }
  }

  onDeleteRecord(record: T) {
    this.isLoading = true
    this.api.delete(record as T).subscribe(response => {
      this.isLoading = false
      this.gridReloadSubject.next(true)
      this.toastService.showSuccess('Datensatz wurde erfolgreich gelöscht!')
    }, error => {
      this.isLoading = false
      this.toastService.showError('Datensatz konnte nicht gelöscht werden!')
    })
  }

  onViewRecord(record: T) {
    this.modalService.show(AbstractRecordsViewComponent, {
      inputs: {
        record: record,
        modelConfig: this.modelConfig,
        closeBtnText: "schließen",

      },
      title: record.data.id+'',
    })
  }

  closeModal() {
    if (this.openedModal) this.openedModal?.close()
    this.openedModal = undefined
  }

  ngOnInit(): void {
    this.gridShowAddRecordButton = this.authService.hasAbility("spa-user")
  }
}
