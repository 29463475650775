import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface ColorRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  pricetype_id: number
  wasmuth_id: number
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface ColorRecordInterface extends RecordInterface<ColorRecordDataInterface> {
}

export const ColorModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "colors",
  allowExportXlsx: true,
  title: "Farben",
  titleSingular: "Farbe",
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { prop: 'name', type: "text", title: 'Name', isNameProperty: true, validators: [ Validators.required ]},
    { type: "constraint", title: 'Preistyp', prop: 'pricetype_id', constraint: { table: 'price_types' }, validators: [ Validators.required ]},
    { prop: 'wasmuth_id', type: "int", title: "Wasmuth ID"},
    { prop: "created_by", ability: "spa-poweruser", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-poweruser", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-poweruser", type: "datestring", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-poweruser", type: "datestring", title: 'Bearbeitet am', edit: false},
  ]
}
