<nav class="navbar">
  <ul class="navbar-nav">
    <li class="nav-item">
      <span class="nav-title">Werbeträger</span>
      <ul>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/ad-spendings/editions']" [routerLinkActive]="'active'">Printmedien</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/ad-spendings/list']" [routerLinkActive]="'active'">Anzeigen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/monitor']" [routerLinkActive]="'active'">Monitor</a>
        </li>
      </ul>
    </li>
    <li class="nav-item" *ngIf="userIsSpaUser">
      <span class="nav-title">Wasmuth Tabellen</span>
      <ul>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/wasmuth-tables/media']" [routerLinkActive]="'active'">Werbeträger</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/wasmuth-tables/media-ratecards']" [routerLinkActive]="'active'">Media Ratecards</a>
        </li>
      </ul>
    </li>
    <li class="nav-item" *ngIf="userIsPoweruser">
      <span class="nav-title">WEFRA Tabellen</span>
      <ul>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/editions']" [routerLinkActive]="'active'">Printmedien</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/adspendings']" [routerLinkActive]="'active'">Anzeigen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/magazines']" [routerLinkActive]="'active'">Magazine</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/publishers']" [routerLinkActive]="'active'">Verlage</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/target_audiences']" [routerLinkActive]="'active'">Zielgruppen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/companies']" [routerLinkActive]="'active'">Firmen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/company_categories']" [routerLinkActive]="'active'">Firmenkategorien</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/markets']" [routerLinkActive]="'active'">Branchen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/submarkets']" [routerLinkActive]="'active'">Unterbranchen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/products']" [routerLinkActive]="'active'">Produkte</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/product_categories']" [routerLinkActive]="'active'">Produktkategorien</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/motives']" [routerLinkActive]="'active'">Motive</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/categories']" [routerLinkActive]="'active'">Kategorien</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/colors']" [routerLinkActive]="'active'">Farben</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/formats']" [routerLinkActive]="'active'">Formate</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/prices']" [routerLinkActive]="'active'">Preise</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/advertising_forms']" [routerLinkActive]="'active'">Werbeformen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/wefra_ratecards']" [routerLinkActive]="'active'">Wefra Ratecards</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/wefra_pubdates']" [routerLinkActive]="'active'">Wefra Erscheinungsdaten</a>
        </li>
      </ul>
    </li>
    <li class="nav-item" *ngIf="userIsAdmin">
      <span class="nav-title" [routerLink]="['/admin']" [routerLinkActive]="'active'">Admin</span>
      <ul>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/admin/logs']" [routerLinkActive]="'active'">Benutzeraktivität</a>
        </li>
      </ul>
    </li>
  </ul>
</nav>

